import React from "react"; //nombre de la clase y luego el nombre del paquete
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "./Categories.css"
import "./Deals.css"
import backButton from "./backButton.png";
import cancel from "./cancel.png";
import sumButton from "./sumButton.png";
import minusButton from "./minusButton.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import NumericFormat from "react-number-format";
import uuid from "react-uuid";
import * as tf from "@tensorflow/tfjs";
import Webcam from "react-webcam";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import * as docx from "docx";
import {saveAs} from "file-saver";




class Categories extends React.Component {

    constructor(props) {
        super(props);
        this.showCategorySkus = this.showCategorySkus.bind(this);
        this.previousScreen = this.previousScreen.bind(this);
        this.billState =this.billState.bind(this);
        this.showDeal =this.showDeal.bind(this);
        this.selectSku =this.selectSku.bind(this);
        this.cancelSku =this.cancelSku.bind(this);
        this.calculateTotal =this.calculateTotal.bind(this);
        this.addQuantity =this.addQuantity.bind(this);
        this.substractQuantity =this.substractQuantity.bind(this);
        this.closeOrder = this.closeOrder.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.cleanTimer = this.cleanTimer.bind(this);
        this.featDescription = this.featDescription.bind(this);
        this.changeTemperature = this.changeTemperature.bind(this);
        this.changeMiga= this.changeMiga.bind(this);
        this.selectPan= this.selectPan.bind(this);
        /*this.createPDF = this.createPDF.bind(this);
        this.sendPDF = this.sendPDF.bind(this);*/
        this.timer = setInterval(this.cleanTimer, 3600000*24);
        this.createWord = this.createWord.bind(this);
        this.sendWord = this.sendWord.bind(this);
        this.tableCell = this.tableCell.bind(this);
        this.position = this.position.bind(this);
        this.filterAvailableSKU = this.filterAvailableSKU.bind(this);
        this.haversineDistance = this.haversineDistance.bind(this);
        this.findCurrentStore = this.findCurrentStore.bind(this);
        this.offlineCache = this.offlineCache.bind(this);
        this.state = {data: this.offlineCache("data"), data_d:[], data_cuenta:[], selected_sku: null, clickedCategory: null, total: 0.0, quantities:{},
        show_extras: false, orderNumber: parseInt(localStorage.getItem("orderNumber")) || 1, skuDescription: null,
        skuCombo: null, pan: null, stores: [], parentIdentifier: null, currentStore: null, combos: this.offlineCache("combos"), groups: this.offlineCache("groups"),
        availableCombos: [], skus: []};
    }

    async position() {
        await navigator.geolocation.getCurrentPosition((position) => this.findCurrentStore(position.coords.latitude, position.coords.longitude),(error) => console.log(error));
    }

    findCurrentStore(latitude, longitude) {
        let currentStore = this.state.stores.filter((store) => this.haversineDistance(latitude, parseFloat(store.latitude), longitude, parseFloat(store.longitude)) <= 70);
        if (currentStore.length > 0) {
            this.setState({currentStore: currentStore[0]})

        }
    }

    offlineCache(collection, data=null) {
        if (data !== null) {
            this.setState({[collection]: data});
            localStorage.setItem(collection, JSON.stringify(data));
        } else {
            let cache = localStorage.getItem(collection)
            return cache ? JSON.parse(cache) : []
        }


    }

    componentDidMount() {
        fetch("https://apps.getitchile.cl/api/categories").then((response) => response.json()).then((response) => this.offlineCache("data", response))
        fetch("https://apps.getitchile.cl/api/stores").then(result => result.json()).then(data => {this.setState({stores: data}); this.position()});
        fetch("https://apps.getitchile.cl/api/combos").then(result => result.json()).then(data => {this.offlineCache("combos", data)});
        fetch("https://apps.getitchile.cl/api/groups").then(result => result.json()).then(data => {this.offlineCache("groups", data)});
        fetch("https://apps.getitchile.cl/api/skus").then(result => result.json()).then(data => {this.setState({skus: data})});
        fetch("https://apps.getitchile.cl/api/deals").then(result => result.json()).then(data => {this.setState({deals: data})});

    }

    filterAvailableSKU(sku) {
        if ((sku.hasOwnProperty("active") && sku.active) || !sku.hasOwnProperty("active")) {
            if (this.state.currentStore) {
                if (sku.hasOwnProperty("blacklist")) {
                    let index = sku.blacklist.find((store) => store["_id"]["$oid"] === this.state.currentStore["_id"]["$oid"]);
                    return index === undefined;
                }
                else {
                    return true
                }
            }
            return true
        }
        else {
            return false
        }
    }

    haversineDistance(la1, la2, lo1, lo2) {
        let x1 = Math.PI * (la2 -la1) / 180.0;
        let x2 = Math.PI * (lo2 - lo1) /180.0;
        let la1r = Math.PI * la1 / 180.0;
        let la2r = Math.PI * la2 / 180.0;
        let a = Math.pow(Math.sin(x1/2.0), 2) + Math.cos(la1r) * Math.cos(la2r) * Math.pow(Math.sin(x2 / 2.0), 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return c * 6371 * 1000
    }

    showCategorySkus(category) {
        this.setState({clickedCategory:category});
    }

    showDeal(deal) {
        if (deal.hasOwnProperty("skus")) {
            this.showCategorySkus(deal)
        } //else if (deal.hasOwnProperty("skus")) {
        //this.billState(deal.sku)
        //}
    }

    previousScreen() {
        if (this.state.skuDescription !== null) {
            this.setState({skuDescription: null})
        } else{
            this.setState({clickedCategory:null, show_extras: false})
        }


    }

    billState(inputSku, parent, selectedCombo=null) {
        let sku = {...inputSku};
        let combos = sku.combos;
        console.log("combos", combos);
        if (!this.state.show_extras && this.state.clickedCategory !== null && ((combos.length > 0) || this.state.clickedCategory.hasOwnProperty("extras"))) {
            let previousState = this.state.data_cuenta;
            sku.uuid = uuid();
            if (parent) {
                this.setState({parentIdentifier: sku.uuid})
            }
            sku.quantity = 1;
            previousState.push(sku);
            /*if (sku.hasOwnProperty("discount")) {
                let d = {sku_name: "Descuento", quantity: 1, codigo_interno: "ABC", precio: -parseInt("" + sku.discount)}
                previousState.push(d);
            }*/
            this.setState({show_extras: true, skuDescription: null, skuCombo: sku, data_cuenta: previousState, availableCombos: combos})
        } else {
            let previousState = this.state.data_cuenta;
            if (sku.extra) {
                let index = previousState.findIndex((sku) => sku.uuid === this.state.parentIdentifier)
                let skuCombo = previousState.splice(index, 1)[0];
                /*let discount = null;
                if (skuCombo.hasOwnProperty("discount")) {
                    skuCombo = previousState.pop();
                    discount = previousState.pop();
                } else {
                    skuCombo = previousState.pop();
                }*/
                if (skuCombo.hasOwnProperty("extras")) {
                    skuCombo.extras.push(sku);
                } else {
                    skuCombo.extras = [sku];

                }
                previousState.push(skuCombo);
                /*if (discount) {
                    previousState.push(discount);
                }*/
            } else {
                sku.quantity = 1;
                sku.uuid = uuid();
                if (parent) {
                    this.setState({parentIdentifier: sku.uuid})
                }
                previousState.push(sku);
                if (selectedCombo) {
                    let sku1 = this.state.skuCombo._id["$oid"];
                    let sku2 = sku._id["$oid"];
                    let o1 = `${sku1}_${sku2}`;
                    let o2 =  `${sku2}_${sku1}`;
                    let price = selectedCombo.price[o1] || selectedCombo.price[o2];
                    price = parseInt(price?.price);
                    let sku1Price = parseInt(("" + this.state.skuCombo.precio).replace(".", "").replace(",", "."));
                    let sku2Price = parseInt(("" + sku.precio).replace(".", "").replace(",", "."));
                    let d = {sku_name: selectedCombo.name, quantity: 1, codigo_interno: selectedCombo.name.substring(0, 5), precio: price - sku1Price - sku2Price, comboDiscount: true}
                    previousState.push(d);
                }
            }
            this.setState({data_cuenta:previousState});
        }
        this.calculateTotal();
    }

    selectSku(index) {
        this.setState({selected_sku:index});
    }

    cancelSku() {
        let previousState = this.state.data_cuenta;
        previousState.splice(this.state.selected_sku,1);
        this.setState({data_cuenta:previousState});
        this.calculateTotal();
    }

    cancelOrder(order) {
        localStorage.setItem("orderNumber",this.state.orderNumber + 1);
        this.setState({data_cuenta:[], orderNumber: this.state.orderNumber + 1, show_extras: false, total: 0.0, clickedCategory: null})
    }

    calculateTotal() {
        let t = 0.0;
        for(const index in this.state.data_cuenta) {
            let item = this.state.data_cuenta[index];
            t += parseInt(("" + item.precio).replace(".", "").replace(",", ".")) * item.quantity;
            if (item.hasOwnProperty("extras")) {
                for (const extraIndex in item.extras) {
                    let extra = item.extras[extraIndex];
                    t += parseInt(("" + extra.precio).replace(".", "").replace(",", ".")) * item.quantity;
                }
            }
        }
        this.setState({total:t})
    }

    addQuantity() {
        if (this.state.data_cuenta[this.state.selected_sku].codigo_interno !== "ABC") {
            this.state.data_cuenta[this.state.selected_sku].quantity += 1;
        }
        this.calculateTotal();
    }

    substractQuantity() {
        if (this.state.data_cuenta[this.state.selected_sku] !== undefined && this.state.data_cuenta[this.state.selected_sku].codigo_interno !== "ABC") {
            if (this.state.data_cuenta[this.state.selected_sku].quantity === 1) {
                this.cancelSku()
            } else {
                this.state.data_cuenta[this.state.selected_sku].quantity -= 1
            }
        }
        this.calculateTotal();
    }

    closeOrder() {
        if (this.state.data_cuenta.length > 0) {
            let body = {store: this.state.currentStore, skus: this.state.data_cuenta, orderNumber: this.state.orderNumber, total: this.state.total, fulfill: false, quantities: this.state.quantities};
            fetch("https://apps.getitchile.cl/api/selfOrders", {method: "POST", body: JSON.stringify(body)}).then((response) => response.json()).then((data) => {
                fetch(data.pdf).then((response) => response.blob()).then((file) => {
                    let formData = new FormData();
                    formData.append("file", file);
                    fetch("http://localhost:5000", {method: "POST", body: formData});
                });
            });
            localStorage.setItem("orderNumber",this.state.orderNumber+1);
            this.setState({data_cuenta:[], orderNumber: this.state.orderNumber+1, show_extras: false, total: 0.0,
            clickedCategory: null, availableCombos: [], skus: [], skuComo: null});
    }}

    cleanTimer() {
        localStorage.setItem("orderNumber", 1)
        ;
        this.setState({orderNumber: 1});
    }

    featDescription(sku) {

        if (sku.hasOwnProperty("ingredientes")) {
            this.setState({skuDescription: sku})
        } else {
            this.billState(sku, true)
        }

    }


    changeTemperature(sku, temperature) {
        sku["temperature"] = temperature;
        return sku
    }

    changeMiga(sku, miga) {
        sku["miga"] = miga;
        return sku
    }

    selectPan(sku, pan) {
        sku["pan"] = pan;
        this.setState({pan: pan})
        return sku;
    }

    /*sendPDF(output, printer){
        let form = new FormData();
        form.append("file", output);
        form.append("printer", printer);
        form.append("filename", `order_${this.state.orderNumber}.pdf`);
        return {method: "POST", body: form};
    }

    async createPDF() {
        let data = await html2canvas(document.querySelector("#div-impresion"), {scale: 3});
        let image = data.toDataURL("image/png");
        let pdf = new jsPDF("portrait", "pt", "a7");
        let properties = pdf.getImageProperties(image);
        let width = pdf.internal.pageSize.getWidth();
        let height = (properties.height * width) / properties.width;
        pdf.addImage(image, "PNG", 0, 0, width, height);
        let output = pdf.output("blob");
        fetch("http://localhost/", this.sendPDF(output, "R200")).then(response => response.json()).then(body => {
            this.closeOrder();
            fetch("http://localhost/", this.sendPDF(output, "RPT006")).then(response => response.json()).then(body => console.log(body))
        });

    }*/

    tableCell(text, bold) {
        return new docx.TableCell({children: [
                new docx.Paragraph({children: [
                        new docx.TextRun({text: text, bold: bold})
                    ]})
            ]
        })
    }

    /*{this.state.data_cuenta.map((sku, index) => (
                                        <TableRow style={{backgroundColor:(this.state.selected_sku === index) ? "#aaaaaa" : "#ffffff"}} onClick={() => this.selectSku(index)} key={sku.codigo_interno}>
                                            <TableCell className={"contenido"}>{sku.codigo_interno}</TableCell>
                                            <TableCell className={"contenido"}>{this.state.quantities[sku.codigo_interno]}</TableCell>
                                            <TableCell className={"contenido"}>{sku.sku_name} {sku.temperature}</TableCell>
                                            <TableCell className={"contenido"}><NumberFormat value={sku.precio * this.state.quantities[sku.codigo_interno]} displayType={"text"} thousandSeparator={true} prefix={"$"}/></TableCell>
                                        </TableRow>
                                    ))}*/
    sendWord(blob, printer) {
        let form = new FormData();
        form.append("file", blob);
        form.append("printer", printer);
        form.append("filename", `order_${this.state.orderNumber}.docx`);
        return {method: "POST", body: form};
    }

    async createWord() {
        let rows = [];
        for (let index in this.state.data_cuenta) {
            let sku = this.state.data_cuenta[index];
            let row = new docx.TableRow({children: [this.tableCell(sku.codigo_interno, false), this.tableCell(this.state.quantities[sku.codigo_interno], false), this.tableCell(sku.sku_name + " " + ((sku.hasOwnProperty("temperature")) ? sku.temperature : "") + " " + ((sku.hasOwnProperty("miga")) ? sku.miga : "") + " " + ((sku.hasOwnProperty("pan")) ? sku.pan : ""), false), this.tableCell(sku.precio * this.state.quantities[sku.codigo_interno], false)]});
            rows.push(row);
        }
        let table = new docx.Table({rows: [
            new docx.TableRow({children: [
                this.tableCell("Codigo", true), this.tableCell("Ctd", true), this.tableCell("Nombre", true), this.tableCell("Subtotal", true)
                ],tableheader: true}), ...rows
            ]})
        /*let image = await fetch("https://s3.sa-east-1.amazonaws.com/cl.getitchile.images/getitLogo.png");
        let imageBlob = await image.blob();
        console.log(imageBlob);
        let reader = new FileReader();
        reader.onload = () => {
            console.log(reader.result.replace("data:image/png;base64,", "").replace("+","-").replace("/", "_"));
            let logo = new docx.ImageRun({data: reader.result.replace("data:image/png;base64,", "").replace("+","-").replace("/", "_")});
        }
        reader.readAsDataURL(imageBlob);*/
        let document = new docx.Document({sections: [{properties: {page: {margin: {top: 0, right: docx.convertMillimetersToTwip(6), left: docx.convertMillimetersToTwip(6), bottom: docx.convertMillimetersToTwip(15)}, size: {orientation: docx.PageOrientation.PORTRAIT, height: docx.convertMillimetersToTwip(80), width: docx.convertMillimetersToTwip(80)}}}, children: [
                    new docx.Paragraph({children: [
                            new docx.TextRun({text: `Mi orden, ID: ${this.state.orderNumber}`, bold: true})
                        ]}), table, new docx.Paragraph({children: [
                            new docx.TextRun({text: `Total: ${this.state.total}`, bold: true})
                        ]}), new docx.Paragraph({children: [
                            new docx.TextRun({text: ` .`})
                        ]}), new docx.Paragraph({children: [
                            new docx.TextRun({text: `Su pedido se está preparando... `})
                        ]}), new docx.Paragraph({children: [
                        new docx.TextRun({text: `Vaya a caja para hacer el pago de su orden`, bold: true})
                        ]}), new docx.Paragraph({children: [
                            new docx.TextRun({text: `Ya estas ahorrando tiempo con Get it`})
                        ]}), new docx.Paragraph({children: [
                            new docx.TextRun({text: `.`})
                        ]}), new docx.Paragraph({children: [
                            new docx.TextRun({text: `Este ticket no es un documento fiscal`, italics: true})
                        ]})
                ]}]});
        docx.Packer.toBlob(document).then((blob) => {

            fetch("http://localhost/", this.sendWord(blob, "R200")).then(response => response.json()).then(body => {
                this.closeOrder();
                /*fetch("http://localhost/", this.sendWord(blob, "RPT006")).then(response => response.json()).then(body => console.log(body));*/
            });
        });



    }




    render() {
        let leftPanel;
        if (this.state.clickedCategory === null) {
            leftPanel =
                <div className={"grid"}>
                    {this.state.data.map((category, index) => (
                        <div onClick={() => this.showCategorySkus(category)} style={{backgroundImage: (category.hasOwnProperty("video")) ? "" : `url(${category.background})`, backgroundRepeat: "no-repeat", borderRadius: 20, gridRow: (index < 4) ? index + 1 : `${(index == 4) ? 1 : 3} / span 2`, gridColumn: (index < 4) ? 1 : "2 / span 2"}}>
                            {category.hasOwnProperty("url") && <h2 className={"category-name"}>{category.hasOwnProperty("name") ? category.name : category.category_name}</h2>}
                            {category.hasOwnProperty("url") && <img className={"category-image"} src={category.url}/>}
                            {category.hasOwnProperty("video") && (<video autoPlay muted loop playsInline preload className={"category-video"}><source src={category.video} type={"video/mp4"}/></video>)}
                    </div>))}
                </div>
        }
        else {
            if (this.state.show_extras){
                leftPanel = <Box sx={{width: "62vw", alignItems: "flex-start", marginRight: 20}}>
                {this.state.availableCombos.length > 0 &&
                    (<div>
                        <h1 className={"gran-titulo"}>Agrega tu combos</h1>
                            {this.state.availableCombos?.map((combo) => (
                                <Grid container spacing={2}>
                                    {combo.objectGroups?.map((group) => (
                                            group.objectSKUs?.map((sku) => (
                                                <Grid item xs={4} onClick={() => this.billState(sku, false, combo)}>
                                                    {sku.hasOwnProperty("video") && (<video className={"sku-video"}>
                                                        <source src={sku.video} type={"video/mp4"}/>
                                                    </video>)}
                                                    {sku.hasOwnProperty("url") &&
                                                        <img className={"combo-grid-bebida"} src={sku.url}/>}
                                                    <h2 className={"sku-name"}>{sku.sku_name}</h2><h2 className={"sku-name"}>Precio: {sku.precio}</h2>
                                                </Grid>
                                                )
                                            )
                                        )
                                    )}
                                </Grid>
                            ))}

                    </div>)}
                    {this.state.clickedCategory.hasOwnProperty("extras") && (
                        <div>
                            <h1 className={"gran-titulo"}>Agrega un extra</h1>
                            <Grid container spacing={4}>
                                {this.state.clickedCategory.extras.map((sku) => (
                                    sku && (
                                        <Grid item xs={4} onClick={() => this.billState(sku, false)} key={sku.id}>
                                            <div className={"extra-name-div"}>
                                                <h2 className={"extra-name"}>{sku.sku_name}</h2>
                                                <h2 className={"sku-name"}>Precio: {sku.precio}</h2>
                                            </div>
                                        </Grid>
                                    )
                                ))}
                            </Grid>
                        </div>
                    )}

                </Box>}
             else {
                if (this.state.skuDescription === null) {
                    leftPanel = <Box sx={{width:"62vw", alignItems:"flex-start", marginRight:20}}>
                        <h1 className={"gran-titulo"}>Elige tu comida</h1>
                        <Grid className={"sku-grid"} container spacing={2}>
                            {this.state.clickedCategory.skus.filter((sku) => this.filterAvailableSKU(sku)).map((sku) => (
                                <Grid className={"sku-grid-item"} item xs={4} onClick={() => this.featDescription(sku)}>
                                    <img className={"sku-image"} src={sku.url}/>
                                    <h2 className={"sku-name"}>{sku.sku_name}</h2><h2 className={"sku-name"}>Precio: {sku.precio}</h2>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                } else {
                    leftPanel = <Box sx={{width:"62vw", alignItems:"flex-start", marginRight:20}}>
                        <h1 className={"gran-titulo"}>Tu {this.state.skuDescription.sku_name} contiene:</h1>
                        <div className={"foto-grande-div"}><img className={"foto-grande"} src={this.state.skuDescription.url}/>
                        <Grid container spacing={2}>
                            {this.state.skuDescription.ingredientes.map((i) => (
                                <Grid item xs={3}>
                                    <p className={"ingredientes"}>{i}</p>
                                </Grid>
                            ))}
                        </Grid>
                            </div>
                        {this.state.clickedCategory.hasOwnProperty("tipo_pan") &&

                            <div className={"botones-pan"}>
                                {this.state.clickedCategory.tipo_pan.map((pan) =>
                                    <div className={"boton-tipo-pan"} onClick={() => this.selectPan(this.state.skuDescription, pan.sku_name)}><img style={{borderColor: "#F18F27", borderWidth: "5px", borderStyle: (this.state.pan === pan.sku_name) ? "solid" : "none"}} src={pan.url}/><h1 style={{color: "white"}}>{pan.sku_name}</h1></div>
                            )}
                            </div>

                        }
                        {(this.state.clickedCategory.hasOwnProperty("miga") && this.state.clickedCategory.miga) &&
                        (<div className={"botones-caliente-frio"}>
                            <h1 className={"boton-final"} style={{width: "45%", marginLeft: "5%", backgroundColor: "#ff0000"}} onClick={() => this.billState(this.changeMiga(this.state.skuDescription, "(Con miga)"), true)}>Con miga</h1>
                            <h1 className={"boton-final"} style={{width: "45%", marginLeft: "5%", backgroundColor: "#0000ff"}} onClick={() => this.billState(this.changeMiga(this.state.skuDescription, "(Sin miga)"), true)}>Sin miga</h1>
                        </div>)}
                        {(this.state.clickedCategory.hasOwnProperty("temperatura") && this.state.clickedCategory.temperatura) &&
                            (<div className={"botones-caliente-frio"}>
                                <h1 className={"boton-final"} style={{width: "45%", marginLeft: "5%", backgroundColor: "#ff0000"}} onClick={() => this.billState(this.changeTemperature(this.state.skuDescription, "(Caliente)"), true)}>Caliente</h1>
                                <h1 className={"boton-final"} style={{width: "45%", marginLeft: "5%", backgroundColor: "#0000ff"}} onClick={() => this.billState(this.changeTemperature(this.state.skuDescription, "(Frío)"), true)}>Frío</h1>
                            </div>)}
                    </Box>
                }

            }
        }

        return (
            <div className={"fondo-pantalla"} style={{backgroundImage: `url(${(this.state.clickedCategory !== null) ? this.state.clickedCategory.background : ""})`}}>
                <div className={"separador-de-columnas"}>
                    {leftPanel}
                    <Box sx={{width:"30vw", alignItems:"flex-start"}} className={"right-panel"}>
                        <TableContainer sx={{height:"50vh", width:"30vw", marginBottom: "60px"}}>
                            <div id={"div-impresion"}>
                            <h1 className={"mi-orden"}>Mi orden, ID: {this.state.orderNumber}</h1>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={"titulo"}>Código</TableCell>
                                        <TableCell className={"titulo"}>Ctd</TableCell>
                                        <TableCell className={"titulo"}>Nombre</TableCell>
                                        <TableCell className={"titulo"}>Subtotal</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.data_cuenta.map((sku, index) => (
                                        <TableRow style={{backgroundColor:(this.state.selected_sku === index) ? "#aaaaaa" : "#ffffff"}} onClick={() => this.selectSku(index)} key={sku.codigo_interno}>
                                            <TableCell className={"contenido"}>{sku.codigo_interno}</TableCell>
                                            <TableCell className={"contenido"}>{sku.quantity}</TableCell>
                                            <TableCell className={"contenido"}>
                                                <p>{sku.sku_name} {sku.temperature} {sku.miga} {sku.pan}</p>
                                                {sku.hasOwnProperty("extras") && sku.extras.map((extra) => (
                                                    <p style={{paddingLeft: 24}}>{extra.sku_name}</p>
                                                ))}
                                            </TableCell>
                                            <TableCell className={"contenido"}>
                                                <p><NumericFormat value={parseInt(("" + sku.precio).replace(".", "").replace(",", ".")) * sku.quantity} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} prefix={"$"}/></p>
                                                {sku.hasOwnProperty("extras") && sku.extras.map((extra) => (
                                                    <p><NumericFormat value={parseInt(("" + extra.precio).replace(".", "").replace(",", ".")) * sku.quantity} displayType={"text"} thousandSeparator={"."} decimalSeparator={","} prefix={"$"}/></p>
                                                ))}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <h1 className={"titulo"}>Total <NumericFormat displayType={"text"} value={this.state.total} thousandSeparator={"."} decimalSeparator={","} prefix={"$"}/></h1>
                            </div>
                        </TableContainer>
                        <h1 onClick={this.closeOrder} className={"boton-final"} >Finalizar tu orden</h1>
                        <div className={"botones-cantidad"}>
                            <img src={sumButton} className={"add-quantity"} onClick={this.addQuantity}/>
                            <img src={minusButton} className={"substract-quantity"} onClick={this.substractQuantity}/>
                        </div>
                        <div className={"botones-carrito-completo"}>
                            <div className={"back-div"}>
                                <img onClick={this.previousScreen} className={"back-arrow"} src={backButton}/>
                                <h1 className={"font-arrow"}>Anterior</h1>
                            </div>
                            <div className={"cancel-div"}>
                                <img onClick={this.cancelOrder} className={"cancel-equis"} src={cancel}/>
                                <h1 className={"font-arrow"}>Cancelar</h1>
                            </div>
                        </div>
                    </Box>
                </div>
            </div>);
    }
}

export default Categories;
