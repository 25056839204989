import React from "react";
import {
    TextField,
    Button,
    Box,
    FormLabel,
    InputLabel,
    Select,
    MenuItem,
    Collapse,
    IconButton,
    Autocomplete
} from "@mui/material";
import "./Admin.css";
import "./Categories.css";
import {sha256} from "js-sha256";
import {Table, TableContainer, TableHead, TableRow, TableBody, TableCell, FormGroup, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, Input, Alert} from "@mui/material";
import getitLogo from "./getitLogo.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ObjectTable from "./ObjectTable";
import CreateObject from "./CreateObject";
import EditIcon from '@mui/icons-material/Edit';
import {Edit} from "@mui/icons-material";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";


class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.menu = {administracion: {name: "Administración", submenu: [{name: "SKUs", action: (self) => self.option("SKUs", "SKU"), role: "superadmin"}, {name: "Familias", action: (self) => self.option("Familias", "Family"), role: "superadmin"}, {name: "Ingredientes", action: (self) => self.option("Ingredientes", "Ingredients"), role: "superadmin"}, {name: "Clientes", action: (self) => self.option("Clientes", "Customers"), role: "cashier"}, {name: "Proveedores", action: (self) => self.option("Proveedores", "Supplier"), role: "superadmin"}, {name: "Tiendas", action: (self) => self.option("Tiendas", "Stores"), role: "superAdmin"}, {name: "Impuestos", action: (self) => self.option("Impuestos", "Taxes"), role: "superadmin"}, {name: "Usuarios", action: (self) => self.option("Usuarios", "User"), role: "superadmin"}, {name: "Formas de pago", action: (self) => self.option("Formas de pago", "PaymentMethod"), role: "superadmin"}]},
        comercial: {name: "Comercial", submenu: [{name: "Grupos", action: (self) => self.option("Grupos", "Group"), role: "superadmin"}, {name: "Combos", action: (self) => self.option("Combos", "Combo"), role: "superadmin"}, {name: "Venta al mayor", action: (self) => self.option("Ventas al mayor", "Wholesale"), role: "superadmin"}, {name: "Descuentos", action: (self) => self.option("Descuentos", "Discount"), role: "superadmin"}]},
        ventas: {name: "Ventas", submenu: [{name: "Facturas", action: null, role: "cashier"}, {name: "Guía de despacho", action: null, role: "cashier"}, {name: "Nota de crédito", action: null, role: "cashier"}]},
        compras: {name: "Compras", submenu: [{name: "Ingreso factura .xml", action: null, role: "cashier"}, {name: "Ingreso factura manual", action: (self) => self.option("Ingreso factura manual", "Ingreso factura manual"), role: "storechief"}]},
        stock: {name: "Stock", submenu: [{name: "Movimiento mercadería por ajuste", action: null, role: "storechief"}, {name: "Movieminto mercadería por traspaso", action: null, role: "storechief"}, {name: "Egreso devolución al proveedor", action: null, role: "storechief"}, {name: "Inventario", action: (self) => self.option("Inventario", "Inventario"), role: "cashier"}, {name: "Modos de defecto de ajustes", action: (self) => self.option("Modos de defecto de ajustes", "Modos de defectos de ajustes"), role: "superadmin"}]},
        reportes: {name: "Reportes", submenu: [{name: "Lista maestra", action: null, role: "storechief"}, {name: "Lista empleados", action: null, role: "storechief"}, {name: "Ventas", action: null, role: "storechief"}, {name: "Movimientos de mercadería", action: null, role: "storechief"}, {name: "Movimientos de dinero", action: null, role: "storechief"}, {name: "Cierres de caja", action: null, role: "storechief"}, {name: "Margen de contribución", action: null, role: "storechief"}, {name: "Abastecimiento general tienda", action: (self) => self.option("Abastecimiento general tienda", "Availability"), role: "storechief"}, , {name: "Abastecimiento Uber Eats", action: (self) => self.option("Abastecimiento Uber Eats", "UberAvailability"), role: "storechief"}, {name: "Abastecimiento Rappi Tienda", action: (self) => self.option("Abastecimiento Rappi Tienda", "RappiAvailability"), role: "storechief"}]},
        sii: {name: "SII", submenu: [{name: "Consulta de estado de documentos", action: null, role: "storechief"}, {name: "Reenvío de documentos", action: null, role: "storechief"}, {name: "Libros", action: null, role: "storechief"}]},
        autocomanda: {name: "Autocomanda", submenu: [{name: "Categorías", action: (self) => self.option("Categorías", "Category"), role: "storechief"}, {name: "Promociones", action: null, role: "storechief"}, {name: "Layout", action: null, role: "storechief"}]},
        caja: {name: "Caja", submenu: [{name: "Catalogos", action: (self) => self.option("Catálogos", "Catalog"), role: "superadmin"}, {name: "Crear cajas", action: (self) => self.option("Cajas", "CashRegister"), role: "storechief"}, {name: "Movimientos de efectivo", action: (self) => self.option("Movimientos de efectivo", "CashMovement"), role: "superadmin"}]},
        cerrarSesion: {name: "Cerrar sesión", submenu: []}
        }

        this.initialSKU = {sku_name: null, codigo_interno: null, barra: null, ingredientes: null, blacklist: [], stockMin: "0",
            stockMax: "0", family: null, totemCheckbox: true, cost: null, precio: null, extra: false, supplier: [], selectedSKU: null, create: false};

        this.initialState = {
            price: null, login: false, selectedOption: null, categories: null, selectedCategory: null,
            category_name: null, email: null, password: null, url: null, stores: [], selectedStore: null,
            name: null, longitude: null, latitude: null, address: null, storeLatitude: null,
            storeLongitude: null, selectedUser: null, users: null, role: null, loginEmail: null,
            loginPassword: null, loginUser: null, catalogs: [], selectedCatalog: null,
            addSKU: false, skus: [], families: [], groups: [], customers: [],
            suppliers: [], ingredients: [], searchSKU: "", filteredSKUs: [], selectedPaymentMethod: null,
            selectedTaxes: [], taxes: [], paymentMethods: [], alert: null, companies: [], selectedCompany: null, cashRegisters: [],
            selectedCashRegister: null, mac: null, store: null, selectedCashMoveType: null, cashMoveType: null, cashMoveTypes: [],
            administracionOpen: false, comercialOpen: false, ventasOpen: false, comprasOpen: false, stockOpen: false, reportesOpen: false,
            siiOpen: false, autocomandaOpen: false, cerrarSesionOpen: false, editSupplier: false, editFamily: false, editStockMin: false,
            editStockMax: false, selfOrderDiscount: 0, supplierDropdown: null, familyDropdown: null, storeDropdown: null, editCostPrice: false,
            bulkPriceEdit: -1, bulkCostEdit: -1, wholeSales: [], discounts: [], ...this.initialSKU, stocks: [], supplierInvoices: [],
            selectedStock: null, defectMode: null, defectModes: [], chiefAdjustment: null, adjustmentRowIndex: null, uberAvailabilities: [],
            uberStores: [], uberStartDate: dayjs(), uberEndDate: dayjs(), rappiAvailabilities: [],
            rappiStores: [], rappiStartDate: dayjs(), rappiEndDate: dayjs(), availabilities: [],
            availabilityStores: [], availabilityStartDate: dayjs(), availabilityEndDate: dayjs(), skuPage: 1
        };

        this.state = {...this.initialState};

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.createCategory = this.createCategory.bind(this);
        this.createCatalog = this.createCatalog.bind(this);
        this.addToCatalog = this.addToCatalog.bind(this);
        this.removeCatalogSKUs = this.removeCatalogSKUs.bind(this);
        this.createSKU = this.createSKU.bind(this);
        this.filterSKUs = this.filterSKUs.bind(this);
        this.filterSKUsAll = this.filterSKUsAll.bind(this);
        this.createObject = this.createObject.bind(this);
        this.createStore = this.createStore.bind(this);
        this.createCashRegister = this.createCashRegister.bind(this);
        this.createCashMoveType = this.createCashMoveType.bind(this);
        this.createUser = this.createUser.bind(this);
        this.createPaymentMethod = this.createPaymentMethod.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.linkSKUCategory = this.linkSKUCategory.bind(this);
        this.desactivateSKU = this.desactivateSKU.bind(this);
        this.deleteSKU = this.deleteSKU.bind(this);
        this.deleteStore = this.deleteStore.bind(this);
        this.deleteCashRegister = this.deleteCashRegister.bind(this);
        this.deleteCashMoveType = this.deleteCashMoveType.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deletePaymentMethod = this.deletePaymentMethod.bind(this);
        this.toggleBlacklist = this.toggleBlacklist.bind(this);
        this.position = this.position.bind(this);
        this.uploadImage = this.uploadImage.bind(this);
        this.option = this.option.bind(this);
        this.addSupplierDropdown = this.addSupplierDropdown.bind(this);
        this.setSupplier = this.setSupplier.bind(this);
        this.filterSupplier = this.filterSupplier.bind(this);
        this.stocksByStore = this.stocksByStore.bind(this);
        this.adjustStock = this.adjustStock.bind(this);
        this.removeCost = this.removeCost.bind(this);
        this.editCost = this.editCost.bind(this);
        this.uberAvailability = this.uberAvailability.bind(this);
        this.rappiAvailability = this.rappiAvailability.bind(this);
        this.availabilities = this.availabilities.bind(this);
        this.pageSkus = this.pageSkus.bind(this);
    }

    async position() {
        await navigator.geolocation.getCurrentPosition((position) => this.setState({storeLatitude: position.coords.latitude, storeLongitude: position.coords.longitude}), (error) => console.log(error));
    }

    componentDidMount() {
        if (this.state.loginUser) {
            this.reloadData();
        }
        this.position().then(() => console.log(this.state.storeLatitude, this.state.storeLongitude));
    }

    componentWillUnmount() {
        this.setState(this.initialState);
    }

    login(event) {
        let body = {email: this.state.loginEmail, password: sha256(this.state.loginPassword)};
        fetch("https://apps.getitchile.cl/api/login", {method: "post", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.setState({login: data.status === "Ok", loginUser: data.user}); this.reloadData()})
        event.preventDefault();
    }

    logout() {
        this.setState({loginEmail: null, loginPassword: null, loginUser: null, login: false})
    }

    createCategory(event) {
        let body = {category_name: this.state.category_name};
        fetch("https://apps.getitchile.cl/api/categories", {method: "post", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.setState({create: false});
        this.reloadData();})

        event.preventDefault();
    }

    createCatalog(event, edit) {
        if (edit) {
            let body = {name: this.state.name, skus: []};
            fetch("https://apps.getitchile.cl/api/catalogs?catalog_id=" + this.state.selectedCatalog["_id"]["$oid"], {method: "put", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.setState({create: false, selectedCatalog: null});
                this.reloadData();})
        } else {
        let body = {name: this.state.name, skus: []};
        fetch("https://apps.getitchile.cl/api/catalogs", {method: "post", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.setState({create: false});
            this.reloadData();})
        }

        event.preventDefault();
    }

    createSKU(event, edit) {
        let body = {sku_name: this.state.sku_name, codigo_interno: this.state.codigo_interno, barra: this.state.barra, url: this.state.url, ingredientes: this.state.ingredientes, taxes: this.state.selectedTaxes, family: this.state.family, stockMin:this.state.stockMin, stockMax: this.state.stockMax, extra: this.state.extra, discount: this.state.selfOrderDiscount, cost: this.state.cost, precio: this.state.price}

        if (edit) {
            body.sku_name = (body.sku_name) ? body.sku_name : this.state.selectedSKU.sku_name;
            body.codigo_interno = (body.codigo_interno) ? body.codigo_interno : this.state.selectedSKU.codigo_interno;
            body.precio = (body.precio) ? body.precio : this.state.selectedSKU.precio;
            body.cost = (body.cost) ? body.cost : this.state.selectedSKU.cost;
            body.url = (body.url) ? body.url : this.state.selectedSKU.url;
            body.ingredientes = (body.ingredientes) ? body.ingredientes.split(",") : this.state.selectedSKU.ingredientes;
            body.blacklist = (body.blacklist) ? body.blacklist : this.state.selectedSKU.blacklist;
            body.family = (body.family) ? body.family : this.state.selectedSKU.family;
            body.stockMin = (body.stockMin) ? body.stockMin : this.state.selectedSKU.stockMin;
            body.stockMax = (body.stockMax) ? body.stockMax : this.state.selectedSKU.stockMax;

            body.discount =  (body.discount) ? body.discount : this.state.selectedSKU.discount;
            fetch(`https://apps.getitchile.cl/api/skus?sku_id=${this.state.selectedSKU["_id"]["$oid"]}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({create: false, editSupplier: false, editFamily: false, editStockMin: false, editStockMax: false, bulkPriceEdit: -1, bulkCostEdit: -1, price: null, cost: null});
                alert("SKU editado con éxito");
                this.reloadData()
            });
        } else {
            fetch("https://apps.getitchile.cl/api/skus", {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                if (this.state.selectedCategory){
                    this.linkSKUCategory(data)
                } else {
                    this.reloadData();
                }
                alert("SKU creado con éxito");
            });
        }
        event.preventDefault();
    }

    createStore(event, edit) {
        let body = {name: this.state.name, latitude: this.state.latitude, longitude: this.state.longitude, address: this.state.address};
        if (edit) {
            body.name = (body.name) ? body.name : this.state.selectedStore.name;
            body.latitude = (body.latitude) ? body.latitude : this.state.selectedStore.latitude;
            body.longitude = (body.longitude) ? body.longitude : this.state.selectedStore.longitude;
            body.address = (body.address) ? body.address : this.state.selectedStore.address;
            fetch(`https://apps.getitchile.cl/api/stores?store_id=${this.state.selectedStore["_id"]["$oid"]}`, {
                method: "put",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedStore: null, create: false});
                this.reloadData();
            });
        } else {
            fetch("https://apps.getitchile.cl/api/stores", {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedStore: null, create: false});
                this.reloadData();
            });
        }
        event.preventDefault();
    }

    createCashRegister(event, edit) {
        let body = {name: this.state.name, store: this.state.store, mac: this.state.mac};
        if (edit) {
            body.name = (body.name) ? body.name : this.state.selectedCashRegister.name;
            body.store = (body.store) ? body.store : this.state.selectedCashRegister.store;
            body.mac = (body.mac) ? body.mac : this.state.selectedCashRegister.mac;
            fetch(`https://apps.getitchile.cl/api/cashRegisters?cash_register_id=${this.state.selectedCashRegister["_id"]["$oid"]}`, {
                method: "put",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedCashRegister: null, create: false});
                this.reloadData();
            });
        } else {
            fetch("https://apps.getitchile.cl/api/cashRegisters", {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedCashRegister: null, create: false});
                this.reloadData();
            });
        }
        event.preventDefault();
    }

    createCashMoveType(event, edit) {
        let body = {name: this.state.name, type: this.state.cashMoveType};
        if (edit) {
            body.name = (body.name) ? body.name : this.state.selectedCashMoveType.name;
            body.type = (body.type) ? body.type : this.state.selectedCashMoveType.type;
            fetch(`https://apps.getitchile.cl/api/cashMoveTypes?cash_move_type_id=${this.state.selectedCashMoveType["_id"]["$oid"]}`, {
                method: "put",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedCashMoveType: null, create: false});
                this.reloadData();
            });
        } else {
            fetch("https://apps.getitchile.cl/api/cashMoveTypes", {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedCashMoveTypes: null, create: false});
                this.reloadData();
            });
        }
        event.preventDefault();
    }

    createPaymentMethod(event, edit) {
        if (this.state.url) {
            let body = {name: this.state.name, url: this.state.url};
            if (edit) {
                body.name = (body.name) ? body.name : this.state.selectedPaymentMethod.name;
                body.url = (body.url) ? body.url : this.state.selectedPaymentMethod.url;
                fetch(`https://apps.getitchile.cl/api/paymentMethods?payment_method_id=${this.state.selectedPaymentMethod["_id"]["$oid"]}`, {
                    method: "put",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(body)
                }).then(response => response.json()).then(data => {
                    this.setState({selectedPaymentMethod: null, create: false, alert: null});
                    this.reloadData();
                });
            } else {
                fetch("https://apps.getitchile.cl/api/paymentMethods", {
                    method: "post",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify(body)
                }).then(response => response.json()).then(data => {
                    this.setState({selectedPaymentMethod: null, create: false});
                    this.reloadData();
                });
            }} else {
            this.setState({alert: "No se subió la imagen"})
        }
        event.preventDefault();
    }

    createUser(event, edit) {
        let body = {name: this.state.name, email: this.state.email, password: this.state.password, role: this.state.role, company: this.state.selectedCompany};
        if (edit) {
            body.name = (body.name) ? body.name : this.state.selectedUser.name;
            body.email = (body.email) ? body.email : this.state.selectedUser.email;
            body.password = (body.password) ? body.password : this.state.selectedUser.password;
            body.role = (body.role) ? body.role : this.state.selectedUser.role;
            body.company = (body.company) ? body.company : this.state.selectedUser.company;
            fetch(`https://apps.getitchile.cl/api/users?user_id=${this.state.selectedUser["_id"]["$oid"]}`, {
                method: "put",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedUser: null, create: false});
                this.reloadData();
            });
        } else {
            fetch("https://apps.getitchile.cl/api/users", {
                method: "post",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({selectedUser: null, create: false});
                this.reloadData();
            });
        }
        event.preventDefault();
    }

    pageSkus(page) {
        fetch(`https://apps.getitchile.cl/api/skus?page=${page}`).then(result => result.json()).then(data => {this.setState({skus: data, filteredSKUs: data})});
        this.setState({skuPage: page});
    }

    reloadData() {
        /*this.setState({...this.initialSKU, name: null});*/
        fetch("https://apps.getitchile.cl/api/categories").then(result => result.json()).then(data => this.setState({categories: data, selectedCategory: null}));
        fetch("https://apps.getitchile.cl/api/stores").then(result => result.json()).then(data => this.setState({stores: data, selectedStore: null}));
        fetch("https://apps.getitchile.cl/api/users").then(result => result.json()).then(data => this.setState({users: data, selectedUser: null}));
        fetch("https://apps.getitchile.cl/api/catalogs").then(result => result.json()).then(data => this.setState({catalogs: data, selectedUser: null}));
        this.pageSkus(1);
        fetch("https://apps.getitchile.cl/api/families").then(result => result.json()).then(data => {this.setState({families: data})});
        fetch("https://apps.getitchile.cl/api/groups").then(result => result.json()).then(data => {this.setState({groups: data})});
        fetch("https://apps.getitchile.cl/api/combos").then(result => result.json()).then(data => {this.setState({combos: data})});
        fetch("https://apps.getitchile.cl/api/wholeSales").then(result => result.json()).then(data => {this.setState({wholeSales: data})});
        fetch("https://apps.getitchile.cl/api/discounts").then(result => result.json()).then(data => {this.setState({discounts: data})});
        fetch("https://apps.getitchile.cl/api/suppliers").then(result => result.json()).then(data => {this.setState({suppliers: data})});
        fetch("https://apps.getitchile.cl/api/customers").then(result => result.json()).then(data => {this.setState({customers: data})});
        fetch("https://apps.getitchile.cl/api/ingredients").then(result => result.json()).then(data => {this.setState({ingredients: data})});
        fetch("https://apps.getitchile.cl/api/paymentMethods").then(result => result.json()).then(data => {this.setState({paymentMethods: data})});
        fetch("https://apps.getitchile.cl/api/companies").then(result => result.json()).then(data => {this.setState({companies: data})});
        fetch("https://apps.getitchile.cl/api/cashRegisters").then(result => result.json()).then(data => {this.setState({cashRegisters: data})});
        fetch("https://apps.getitchile.cl/api/taxes").then(result => result.json()).then(data => {this.setState({taxes: data})});
        /*fetch("http://localhost:5000/mac").then(result => result.json()).then(data => {this.setState({mac: data.mac})});*/
        fetch("https://apps.getitchile.cl/api/cashMoveTypes").then(result => result.json()).then(data => {this.setState({cashMoveTypes: data})});
        fetch("https://apps.getitchile.cl/api/stocks?email=" + this.state.loginUser.email).then(result => result.json()).then(data => {this.setState({stocks: data})});
        fetch("https://apps.getitchile.cl/api/supplierInvoices").then(result => result.json()).then(data => {this.setState({supplierInvoices: data})});
        fetch("https://apps.getitchile.cl/api/defectModes").then(result => result.json()).then(data => {this.setState({defectModes: data})});

    }

    linkSKUCategory(sku) {
        let body = this.state.selectedCategory;
        if (body.hasOwnProperty("skus")) {
            body["skus"].push(sku);
        } else {
            body["skus"] = [sku];
        }
        fetch(`https://apps.getitchile.cl/api/categories?category_id=${this.state.selectedCategory["_id"]["$oid"]}`, {method: "put", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.reloadData();});
        }

    desactivateSKU(sku) {
        sku["active"] = (sku.hasOwnProperty("active")) ? !sku.active : false;
        fetch(`https://apps.getitchile.cl/api/skus?sku_id=${sku["_id"]["$oid"]}`, {method: "put", headers: {"Content-Type": "application/json"}, body: JSON.stringify(sku)}).then(response => response.json()).then(data => this.reloadData());
    }

    deleteSKU(sku) {
        fetch(`https://apps.getitchile.cl/api/skus?sku_id=${sku["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(sku)}).then(response => response.json()).then(data => this.reloadData());
    }

    deleteStore(store) {
        fetch(`https://apps.getitchile.cl/api/stores?store_id=${store["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(store)}).then(response => response.json()).then(data => this.reloadData());
    }

    deleteObject(name, object, collection, container, select, inputs) {
        console.log("se llama el deleteObject");
        fetch(`https://apps.getitchile.cl/api/${collection}?${name}_id=${object["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(object)}).then(response => response.json()).then(data => {
            let update = {[select] : undefined};
            for (let index in inputs) {
                console.log(inputs[index].name);
                update[inputs[index].name] = "";
            }
            container.setState(update);
            container.reloadData()});
    }

    deleteCashRegister(register) {
        fetch(`https://apps.getitchile.cl/api/cashRegisters?cash_register_id=${register["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(register)}).then(response => response.json()).then(data => this.reloadData());
    }

    deleteCashMoveType(register) {
        fetch(`https://apps.getitchile.cl/api/cashMoveTypes?cash_move_type_id=${register["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(register)}).then(response => response.json()).then(data => this.reloadData());
    }

    deletePaymentMethod(method) {
        fetch(`https://apps.getitchile.cl/api/paymentMethods?payment_method_id=${method["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(method)}).then(response => response.json()).then(data => this.reloadData());
    }

    deleteCatalog(catalog) {
        fetch(`https://apps.getitchile.cl/api/catalogs?catalog_id=${catalog["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(catalog)}).then(response => response.json()).then(data => this.reloadData());
    }

    deleteUser(user) {
        fetch(`https://apps.getitchile.cl/api/users?user_id=${user["_id"]["$oid"]}`, {method: "delete", headers: {"Content-Type": "application/json"}, body: JSON.stringify(user)}).then(response => response.json()).then(data => this.reloadData());
    }

    addToCatalog(sku) {
        let body = this.state.selectedCatalog;
        if (body.hasOwnProperty("skus")) {
            body["skus"].push(sku);
        } else {
            body["skus"] = [sku];
        }
        fetch(`https://apps.getitchile.cl/api/catalogs?catalog_id=${this.state.selectedCatalog["_id"]["$oid"]}`,
            {method: "put", headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.setState({addSKU: false}); this.reloadData();});
    }

    filterSKUs(supplier=null, family=null) {
        let familyFilter = (family) ? this.state.skus.filter((sku) => sku.family === family) : this.state.skus;
        console.log(supplier);
        console.log(familyFilter);
        this.setState({filteredSKUs: familyFilter})
        /*let filtered = supplierFilter.filter((x) => this.state.selectedCatalog.skus.find((i) => i.codigo_interno === x.codigo_interno) === undefined)
        if (this.state.searchSKU.length > 0) {
            let searched = filtered.filter((x) => {
                let isCode = x.barra === this.state.searchSKU;
                let isName = x.sku_name.toLowerCase().includes(this.state.searchSKU);
                return isCode || isName
            })
            this.setState({filteredSKUs: searched})
        } else {
            this.setState({filteredSKUs: filtered})
        }*/ //proveedor, familia, tiendas, autocomanda
    }

    filterSKUsAll() {
        fetch(`https://apps.getitchile.cl/api/skus?search=${this.state.searchSKU}`).then(result => result.json()).then(data => {this.setState({skus: data, filteredSKUs: data})});

    }

    removeCatalogSKUs(catalog) {
        let filtered = this.state.skus.filter((x) => catalog.skus.find((i) => i.codigo_interno === x.codigo_interno) === undefined)
        this.setState({filteredSKUs: filtered, selectedCatalog: catalog})

    }


    toggleBlacklist(store, checked) {

        let blacklist = this.state.blacklist;
        let element = blacklist.find(s => s["_id"]["$oid"] === store["_id"]["$oid"]);
        let index = (element !== undefined) ? blacklist.indexOf(element) : -1;
        if (checked) {
            if (index !== -1) {
                blacklist.splice(index, 1)
            }
        }
        else {
            if (index === -1) {
                blacklist.push(store)
            }
        }
        this.setState({blacklist: blacklist})
    }

    toggleTaxes(tax, checked) {

        let selectedTaxes = this.state.selectedTaxes;
        let element = selectedTaxes.find(s => s["_id"]["$oid"] === tax["_id"]["$oid"]);
        let index = (element !== undefined) ? selectedTaxes.indexOf(element) : -1;
        if (checked) {
            if (index === -1) {
                selectedTaxes.push(tax)
            }
        }
        else {
            if (index !== -1) {
                selectedTaxes.splice(index, 1)
            }
        }
        this.setState({selectedTaxes: selectedTaxes})
    }

    uploadImage(event) {
        let file = event.target.files[0]
        let url = "https://apps.getitchile.cl/api/uploadImage"
        let formData = new FormData()
        formData.append("image", file)
        formData.append("filename",file.name)
        let headers = {body: formData, method: "POST"}
        fetch(url, headers).then((response) => response.json()).then((response) => this.setState({url: response.url, alert: null}))
    }

    option(selection, name=null) {
        let states = {selectedOption: selection, create: false, bulkPriceEdit: -1, bulkCostEdit: -1}
        if (name) {
            states[`selected${name}`] = null;
        }
        this.reloadData();
        this.setState(states);
    }

    createObject(event, edit, inputs, collection, object, selected) {
        let body = {};
        for (let index in inputs) {
            body[inputs[index].name] = this.state[`create_${inputs[index].name}`]

        }
        if (edit) {
            for (let index in inputs) {
                body[inputs[index].name] = (body[inputs[index].name]) ? body[inputs[index].name] : this.state[`selected${selected}`][inputs[index].name]
            }
            if (this.state[`selected${selected}`]['background']) {
                body['background'] = this.state[`selected${selected}`]['background']
            }
            if (this.state[`selected${selected}`]['url']) {
                body['url'] = this.state[`selected${selected}`]['url']
            }

            fetch(`https://apps.getitchile.cl/api/${collection}?${object}_id=${this.state[`selected${selected}`]["_id"]["$oid"]}`, {
                method: "PUT",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({[`selected${selected}`]: null, create: false});
                alert("Elemento editado con éxito");
                this.reloadData();
            });
        } else {
            fetch(`https://apps.getitchile.cl/api/${collection}`, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(body)
            }).then(response => response.json()).then(data => {
                this.setState({[`selected${selected}`]: null, create: false});
                alert("Elemento creado con éxito");
                this.reloadData();
            });
        }
        event.preventDefault();
    }

    addSupplierDropdown(event) {
        let supplier = this.state.cost;
        supplier.push({'supplier': {'_id': {'$oid': null}}, 'cost': 0})
        this.setState({supplier: supplier})
    }

    setSupplier(index, supplier) {
        let s =  this.state.cost;
        let k = this.state.suppliers.find((l) => l['_id']['$oid'] === supplier);
        s[index]['supplier'] = k;
        this.setState({cost: s});
    }


    filterSupplier(index, currentSupplier, supplierID) {
        if (currentSupplier["_id"]["$oid"] === supplierID['_id']['$oid']) {
            return true
        } else {
            let s = [];
            for (let c in this.state.cost) {
                s.push(this.state.cost[c]['supplier']['_id']['$oid'])
            }
            s.splice(index, 1);
            return !s.includes(currentSupplier["_id"]["$oid"])
        }
    }

    stocksByStore(store) {
        fetch("https://apps.getitchile.cl/api/stocks?store="+store).then(result => result.json()).then(data => {this.setState({stocks: data})});

    }

    adjustStock() {
        let date = new Date().toISOString();
        let adjustment = {date: date, amount: this.state.chiefAdjustment, defectMode: this.state.defectMode, user: this.state.loginUser, sku: this.state.selectedStock.sku};
        fetch("https://apps.getitchile.cl/api/stocks", {method: "POST", body: JSON.stringify(adjustment)}).then(response => response.json()).then((data) => fetch("https://apps.getitchile.cl/api/stocks").then(response => response.json()).then((data) => this.setState({stocks: data})))
    }

    removeCost(index) {
        let costs = this.state.cost;
        costs.splice(index, 1);
        this.setState({cost: costs});
    }

    editCost(index, value) {
        let costs = this.state.cost;
        costs[index].cost = value;
        this.setState({cost: costs})
    }

    uberAvailability() {
        console.log(this.state.uberStartDate);
        console.log(this.state.uberEndDate);
        fetch("https://apps.getitchile.cl/api/uberAvailabilities?stores="+this.state.uberStores.map((store) => store.id).join(",")+"&start_date="+this.state.uberStartDate.format()+"&end_date="+this.state.uberEndDate.format()).then((response) => response.blob()).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            console.log(this.state.uberStartDate);
            console.log(this.state.uberEndDate);
            link.download = "Reporte"+this.state.uberStores.map((store) => store.name).join(",")+this.state.uberStartDate.format()+"-"+this.state.uberEndDate.format()+".csv";
            link.click();
            window.URL.revokeObjectURL(url);
        }).catch((error) => console.log(error))
    }

    rappiAvailability() {
        console.log(this.state.rappiStartDate);
        console.log(this.state.rappiEndDate);
        fetch("https://apps.getitchile.cl/api/rappiAvailabilities?stores="+this.state.rappiStores.map((store) => store.id).join(",")+"&start_date="+this.state.rappiStartDate.format()+"&end_date="+this.state.rappiEndDate.format()).then((response) => response.blob()).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            console.log(this.state.rappiStartDate);
            console.log(this.state.rappiEndDate);
            link.download = "Reporte"+this.state.rappiStores.map((store) => store.name).join(",")+this.state.rappiStartDate.format()+"-"+this.state.rappiEndDate.format()+".csv";
            link.click();
            window.URL.revokeObjectURL(url);
        }).catch((error) => console.log(error))
    }

    availabilities() {
        fetch("https://apps.getitchile.cl/api/availabilities?stores="+this.state.availabilityStores.map((store) => store.id).join(",")+"&start_date="+this.state.availabilityStartDate.format()+"&end_date="+this.state.availabilityEndDate.format()).then((response) => response.blob()).then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Reporte"+this.state.availabilityStores.map((store) => store.name).join(",")+this.state.availabilityStartDate.format()+"-"+this.state.availabilityEndDate.format()+".csv";
            link.click();
            window.URL.revokeObjectURL(url);
        }).catch((error) => console.log(error))
    }


    render() {
        return (
            <div>
                {!this.state.login &&  <Box component={"form"} noValidate>
                    <h1 className={"admin-title"}>Ingresa tus datos</h1>
                    <div className={"field-div"}>
                        <TextField onChange={event => this.setState({loginEmail: event.target.value})} name={"email"} id={"email"} fullWidth={true} className={"admin-fields"} label={"email"}/>
                    </div>
                    <div className={"field-div"}>
                        <TextField onChange={event => this.setState({loginPassword: event.target.value})} name={"password"} id={"password"} type={"password"} fullWidth={true} className={"admin-fields"} label={"password"}/>
                    </div>
                    <Button className={"login-button"} onClick={this.login}>Iniciar sesión</Button>
                </Box>}
                {this.state.login &&
                    <div className={"admin-home"}>
                        <div className={"left-panel"}>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {Object.entries(this.menu).map(([key, value]) => (
                                            <div>
                                                <TableRow sx={{ '& > *': {borderBottom: 'unset'}}}>
                                                    <TableCell onClick={(event) => this.setState({[`${key}Open`]: !this.state[`${key}Open`]})}><IconButton size={"small"}>{this.state[`${key}Open`] ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}</IconButton>{value.name}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}}>
                                                        <Collapse in={this.state[`${key}Open`]}>
                                                            <Table>
                                                                <TableBody>
                                                                    {value.submenu.map((item) => (
                                                                        <TableRow>
                                                                            <TableCell onClick={() => item.action(this)}>{item.name}</TableCell>
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </div>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className={"right-panel"}>
                            {this.state.alert &&
                                <Alert severity={"error"}>
                                    {this.state.alert}
                                </Alert>
                            }
                            {this.state.selectedOption === null &&
                            <div className={"bienvenida"}>
                                <h1>Bienvenido a la app de admin</h1>
                                <h1>Selecciona una de las opciones a la izquierda</h1>
                                <img className={"logo"} src={getitLogo}/>
                            </div>
                            }
                            {(this.state.selectedOption === "Catálogos" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Catálogos"} container={this} objects={this.state.catalogs} name={"Catalog"} delete={this.deleteObject} collection={"catalogs"}/>}
                            {(this.state.selectedOption === "Categorías" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Categorías"} container={this} objects={this.state.categories} name={"Category"} delete={this.deleteObject} collection={"categories"}/>}
                            {(this.state.selectedOption === "Tiendas" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Tiendas"} container={this} objects={this.state.stores} name={"Store"} delete={this.deleteObject} collection={"stores"}/>}
                            {(this.state.selectedOption === "Familias" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Familias"} container={this} objects={this.state.families} name={"Family"} delete={this.deleteObject} collection={"families"}/>}
                            {(this.state.selectedOption === "Grupos" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Grupos"} container={this} objects={this.state.groups} name={"Group"} delete={this.deleteObject} collection={"groups"}/>}
                            {(this.state.selectedOption === "Proveedores" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Proveedores"} container={this} objects={this.state.suppliers} name={"Supplier"} delete={this.deleteObject} collection={"suppliers"}/>}
                            {(this.state.selectedOption === "Clientes" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Clientes"} container={this} objects={this.state.customers} name={"Customer"} delete={this.deleteObject} collection={"customers"}/>}
                            {(this.state.selectedOption === "Impuestos" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Impuestos"} container={this} objects={this.state.taxes} name={"Tax"} delete={this.deleteObject} collection={"taxes"}/>}
                            {(this.state.selectedOption === "Cajas" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Cajas"} container={this} objects={this.state.cashRegisters} name={"CashRegister"} delete={this.deleteObject} collection={"cashregisters"}/>}
                            {(this.state.selectedOption === "Movimientos de efectivo" && !this.state.create) &&
                                <ObjectTable extraColumns={[{name: "Tipo", property: "type"}]} inputs={[{name: "name"}]} title={"Movimientos de efectivo"} container={this} objects={this.state.cashMoveTypes} name={"CashMoveType"} delete={this.deleteObject} collection={"cashmovetypes"}/>}
                            {(this.state.selectedOption === "Formas de pago" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Formas de pago"} container={this} objects={this.state.paymentMethods} name={"PaymentMethod"} delete={this.deleteObject} collection={"paymentmethods"}/>}
                            {(this.state.selectedOption === "Usuarios" && !this.state.create) &&
                                <ObjectTable extraColumns={[{name: "Correo electrónico", property: "email"}]} inputs={[{name: "name"}]} title={"Usuarios"} container={this} objects={this.state.users} name={"Usuarios"} delete={this.deleteObject} collection={"users"}/>}
                            {(this.state.selectedOption === "Ingredientes" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Ingredientes"} container={this} objects={this.state.ingredients} name={"Ingredient"} delete={this.deleteObject} collection={"ingredients"}/>}
                            {(this.state.selectedOption === "Combos" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Combos"} container={this} objects={this.state.combos} name={"Combo"} delete={this.deleteObject} collection={"combos"} extraColumns={[{name: "Fecha inicio", property: "startDate" }, {name: "Fecha fin", property: "endDate"}]}/>}
                            {(this.state.selectedOption === "Ventas al mayor" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "SKU"}]} property={"SKU"} subproperty={"sku_name"} title={"Promociones de ventas al mayor"} container={this} objects={this.state.wholeSales} name={"Wholesale"} delete={this.deleteObject} collection={"wholeSales"} extraColumns={[{name: "Fecha inicio", property: "startDate" }, {name: "Fecha fin", property: "endDate"}, {name: "Precio promoción", property: "price"}, {name: "Cantidad mínima", property: "minQuantity"}]}/>}
                            {(this.state.selectedOption === "Descuentos" && !this.state.create) &&
                                <ObjectTable inputs={[]} property={"Descuentos"} title={"Promociones de descuentos a clientes o tienda completa"} container={this} objects={this.state.discounts} name={"Discount"} delete={this.deleteObject} collection={"discounts"} extraColumns={[{name: "Fecha inicio", property: "startDate" }, {name: "Fecha fin", property: "endDate"}, {name: "% de descuento", property: "percentage"}, {name: "Familias excluidas", property: "familyBlacklist", collection: this.state.families}, {name: "Tiendas excluidas", property: "storeBlacklist", collection: this.state.stores}, {name: "Cliente", property: "RUT", condition: "allCustomers", conditionLabel: "Todos los clientes", collection: this.state.customers}]}/>}
                            {(this.state.selectedOption === "Ingreso factura manual" && !this.state.create) &&
                                <ObjectTable inputs={[]} extraColumns={[{name: "Fecha", property: "date"}, {name: "Proveedor", property: "supplier", collection: this.state.suppliers, subproperty: "rut"}, {name: "Folio", property: "folio"}]} title={"Ingreso factura manual"} container={this} objects={this.state.supplierInvoices} name={"SupplierInvoice"} delete={this.deleteObject} collection={"supplierInvoices"}/>}
                            {(this.state.selectedOption === "Modos de defecto de ajustes" && !this.state.create) &&
                                <ObjectTable inputs={[{name: "name"}]} title={"Modos de defecto de ajustes"} container={this} objects={this.state.defectModes} name={"DefectMode"} delete={this.deleteObject} collection={"defectModes"}/>}
                            {(this.state.selectedOption === "Grupos" && this.state.create) &&
                                <CreateObject object={"group"} collection={"groups"} create={this.createObject} inputs={[{name: "name"}, {name: "objectSKUs", type: "SKU"}]} container={this} name={"Group"} title={"Grupo"}/>}
                            {(this.state.selectedOption === "Familias" && this.state.create) &&
                                <CreateObject object={"family"} collection={"families"} create={this.createObject} inputs={[{name: "name"}]} container={this} name={"Family"} title={"Familia"}/>}
                            {(this.state.selectedOption === "Proveedores" && this.state.create) &&
                                <CreateObject object={"supplier"} collection={"suppliers"} create={this.createObject} inputs={[{name: "name", placeholder: "Razón social"}, {name: "address", placeholder: "Dirección"}, {name: "phone", placeholder: "Teléfono"}, {name: "rut", placeholder: "RUT"}]} container={this} name={"Supplier"} title={"Proveedor"}/>}
                            {(this.state.selectedOption === "Clientes" && this.state.create) &&
                                <CreateObject object={"customer"} collection={"customers"} create={this.createObject} inputs={[{name: "name", placeholder: "Razón social"}, {name: "address", placeholder: "Dirección"}, {name: "phone", placeholder: "Teléfono"}, {name: "rut", placeholder: "RUT"}]} container={this} name={"Customer"} title={"Cliente"}/>}
                            {(this.state.selectedOption === "Impuestos" && this.state.create) &&
                                <CreateObject object={"tax"} collection={"taxes"} create={this.createObject} inputs={[{name: "name", placeholder: "Nombre del impuesto"}, {name: "value", placeholder: "% del precio neto (Ej: Iva 19% es 0.19)"}]} container={this} name={"Tax"} title={"Impuesto"}/>}
                            {(this.state.selectedOption === "Ingredientes" && this.state.create) &&
                                <CreateObject object={"ingredient"} collection={"ingredients"} create={this.createObject} inputs={[{name: "name"}]} container={this} name={"Ingredient"} title={"Ingrediente"}/>}
                            {(this.state.selectedOption === "Categorías" && this.state.create) &&
                                <CreateObject object={"category"} collection={"categories"} create={this.createObject} inputs={[{name: "name"}, {title: "Agrega URL foto de fondo", name: "background"}, {title: "Agrega URL foto de la categoría", name: "url"}, {searchTitle: "Agrega tipo de pan", title: "Tipos de pan agregados", name: "tipo_pan", type: "SKU"}, {title: "Miga", name: "miga", type: "checkbox"}, {searchTitle: "Agrega los SKUs de la catogoría", name: "skus", type: "SKU", title: "SKUs agregados"} ,{searchTitle: "Agrega los extras",name: "extras", type: "SKU", title: "Extras agregados"}]} container={this} name={"Category"} title={"Categoría"}/>}
                            {(this.state.selectedOption === "Catálogos" && this.state.create) &&
                                <CreateObject object={"catalog"} collection={"catalogs"} create={this.createObject} inputs={[{name: "name"}]} container={this} name={"Catalog"} title={"Catálogo"}/>}
                            {(this.state.selectedOption === "Tiendas" && this.state.create) &&
                                <CreateObject object={"store"} collection={"stores"} create={this.createObject} inputs={[{name: "name"}, {name: "latitude"}, {name: "longitude"}, {name: "address"}]} container={this} name={"Store"} title={"Tienda"}/>}
                            {(this.state.selectedOption === "Combos" && this.state.create) &&
                                <CreateObject object={"combo"} collection={"combos"} create={this.createObject} inputs={[{name: "name"}, {name: "price", type: "priceTable", key: "objectGroups", collection: this.state.groups, subkey: "objectSKUs", subcollection: this.state.skus}, {name: "objectGroups", type: "group"}, {name: "startDate", type: "date"}, {name: "endDate", type: "date"}, {name: "storeBlacklist", type: "store"}]} container={this} name={"Combo"} title={"Combos"}/>}
                            {(this.state.selectedOption === "Ventas al mayor" && this.state.create) &&
                                <CreateObject object={"wholesale"} collection={"wholeSales"} create={this.createObject} inputs={[{name: "SKU", type: "SKUCode"}, {name: "price"}, {name: "startDate", type: "date"}, {name: "endDate", type: "date"}, {name: "minQuantity"}, {name: "storeBlacklist", type: "store"}]} container={this} name={"Wholesale"} title={"la venta al mayor"}/>}
                            {(this.state.selectedOption === "Descuentos" && this.state.create) &&
                                <CreateObject object={"discount"} collection={"discounts"} create={this.createObject} inputs={[{name: "percentage"}, {name: "allCustomers", placeholder: "Todos los clientes", type: "checkbox"}, {name: "RUT", type: "customer", condition: "allCustomers"}, {name: "startDate", type: "date"}, {name: "endDate", type: "date"}, {name: "dayBlacklist", type: "day"}, {name: "storeBlacklist", type: "store"}, {name: "familyBlacklist", type: "family"}]} container={this} name={"Discount"} title={"Descuentos de tienda completa o cliente"}/>}
                            {(this.state.selectedOption === "Ingreso factura manual" && this.state.create) &&
                                <CreateObject object={"supplierInvoice"} collection={"supplierInvoices"} create={this.createObject} inputs={[{name: "folio"}, {name: "date", type: "date"}, {name: "receptionDate", type: "date"}, {name: "user", type: "user", value: this.state.loginUser, display: this.state.loginEmail}, {name: "supplier", type: "suppliers", collection: this.state.suppliers}, {name: "objectSKUs", type: "barcode", collection: this.state.skus}]} container={this} name={"SupplierInvoice"} title={"Ingreso factura manual"}/>}
                            {(this.state.selectedOption === "Modos de defecto de ajustes" && this.state.create) &&
                                <CreateObject object={"defectMode"} collection={"defectModes"} create={this.createObject} inputs={[{name: "name"}]} container={this} name={"DefectMode"} title={"Modos de defecto de ajuste"}/>}
                            {(this.state.selectedOption === "Abastecimiento Uber Eats" && !this.state.create) &&
                                <div>
                                    <Autocomplete value={this.state.uberStores} onChange={(event, values) => this.setState({uberStores: values})} style={{marginBottom: "4px"}} multiple={true} renderInput={(params) => (<TextField {...params} label={"Selecciona las tiendas"}/>)} options={this.state.stores.map((store) => {return {label: store.name, id: store._id["$oid"]}})}/>
                                    <DateTimePicker value={this.state.uberStartDate} onChange={(value) => this.setState({uberStartDate: value})} label={"Fecha de inicio"}/>
                                    <DateTimePicker value={this.state.uberEndDate} onChange={(value) => this.setState({uberEndDate: value})} label={"Fecha de fin"}/>
                                    <Button onClick={this.uberAvailability}>Descargar reporte</Button>
                                </div>
                            }
                            {(this.state.selectedOption === "Abastecimiento Rappi Tienda" && !this.state.create) &&
                                <div>
                                    <Autocomplete value={this.state.rappiStores} onChange={(event, values) => this.setState({rappiStores: values})} style={{marginBottom: "4px"}} multiple={true} renderInput={(params) => (<TextField {...params} label={"Selecciona las tiendas"}/>)} options={this.state.stores.map((store) => {return {label: store.name, id: store._id["$oid"]}})}/>
                                    <DateTimePicker value={this.state.rappiStartDate} onChange={(value) => this.setState({rappiStartDate: value})} label={"Fecha de inicio"}/>
                                    <DateTimePicker value={this.state.rappiEndDate} onChange={(value) => this.setState({rappiEndDate: value})} label={"Fecha de fin"}/>
                                    <Button onClick={this.rappiAvailability}>Descargar reporte</Button>
                                </div>
                            }
                            {(this.state.selectedOption === "Abastecimiento general tienda" && !this.state.create) &&
                                <div>
                                    <Autocomplete value={this.state.availabilityStores} onChange={(event, values) => this.setState({availabilityStores: values})} style={{marginBottom: "4px"}} multiple={true} renderInput={(params) => (<TextField {...params} label={"Selecciona las tiendas"}/>)} options={this.state.stores.map((store) => {return {label: store.name, id: store._id["$oid"]}})}/>
                                    <DateTimePicker value={this.state.availabilityStartDate} onChange={(value) => this.setState({availabilityStartDate: value})} label={"Fecha de inicio"}/>
                                    <DateTimePicker value={this.state.availabilityEndDate} onChange={(value) => this.setState({availabilityEndDate: value})} label={"Fecha de fin"}/>
                                    <Button onClick={this.availabilities}>Descargar reporte</Button>
                                </div>
                            }
                            {(this.state.selectedOption === "Formas de pago" && this.state.create) &&
                                <div>
                                    <div className={"new-sku-button"}>
                                        <h1>{(this.state.selectedPaymentMethod) ? "Editar forma de pago" : "Crear forma de pago"}</h1>
                                        <Box component={"form"} noValidate>
                                            <TextField value={this.state.name} onChange={event => this.setState({name: event.target.value})} id={"name"} name={"name"} label={"Nombre de forma de pago"}/>
                                            <Input type={"file"} onChange={this.uploadImage} placeholder={"Subir imagen"}/>

                                            <Button onClick={(event) => this.createPaymentMethod(event, this.state.selectedPaymentMethod !== null)}>{(this.state.selectedPaymentMethod) ? "Editar" : "Crear"}</Button>
                                        </Box>
                                    </div>
                                </div>}

                            {(this.state.selectedOption === "Categorías" && this.state.selectedCategory !== null) &&
                                <div>
                                    <div className={"new-sku-button"}>
                                        <h1>{this.state.selectedCategory.category_name}</h1>
                                        <Button onClick={() => this.setState({selectedCategory: null})}>Atrás</Button>
                                    </div>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {this.state.selectedCategory.skus.map(sku => (
                                                    <TableRow>
                                                        <TableCell>
                                                            {sku.sku_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => this.desactivateSKU(sku)}>{(sku.hasOwnProperty("active")) ? (sku.active) ? "Desactivar" : "Activar" : "Desactivar"}</Button>
                                                        </TableCell>
                                                    </TableRow>))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>}
                            {(this.state.selectedOption === "Catálogos" && this.state.selectedCatalog !== null && !this.state.create
                                && !this.state.addSKU) &&
                                <div>
                                    <div className={"new-sku-button"}>
                                        <h1>{this.state.selectedCatalog.name}</h1>
                                        <Button onClick={() => this.setState({addSKU: true})}>Clasificar SKU existente</Button>
                                        <Button onClick={() => this.setState({selectedCatalog: null})}>Atrás</Button>
                                    </div>
                                    <TableContainer>
                                        <Table>
                                            <TableBody>
                                                {this.state.selectedCatalog.skus.map(sku => (
                                                    <TableRow>
                                                        <TableCell>
                                                            {sku.sku_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={() => this.desactivateSKU(sku)}>{(sku.hasOwnProperty("active")) ? (sku.active) ? "Desactivar" : "Activar" : "Desactivar"}</Button>
                                                        </TableCell>
                                                    </TableRow>))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>}
                            {(this.state.selectedOption === "SKUs" && this.state.create) &&
                                <div>
                                    <div className={"new-sku-button"}>
                                        <h1>{(this.state.selectedSKU) ? "Editar SKU" : "Crear SKU"}</h1>
                                        <Button onClick={(event) => this.createSKU(event, this.state.selectedSKU !== null)}>{(this.state.selectedSKU) ? "Guardar todo" : "Crear"}</Button>
                                        <Button onClick={(event) => window.location = this.state.selectedSKU.label}>Imprimir etiqueta</Button>
                                        <Box component={"form"} noValidate>
                                            <TextField defaultValue={(this.state.selectedSKU !== null) ? this.state.selectedSKU.sku_name : ""} onChange={event => this.setState({sku_name: event.target.value})} id={"sku_name"} name={"sku_name"} label={"Nombre del SKU"}/>
                                            <TextField defaultValue={(this.state.selectedSKU !== null) ? this.state.selectedSKU.codigo_interno : ""} onChange={event => this.setState({codigo_interno: event.target.value})} id={"codigo_interno"} name={"codigo_interno"} label={"Código interno"}/>
                                            <TextField defaultValue={(this.state.selectedSKU !== null) ? this.state.selectedSKU.barra : ""} onChange={event => this.setState({barra: event.target.value})} id={"barra"} name={"barra"} label={"Código de barra"}/>
                                            <TextField defaultValue={(this.state.selectedSKU !== null) ? this.state.selectedSKU.url : ""} onChange={event => this.setState({url: event.target.value})} id={"url"} name={"url"} label={"Subir foto"}/>
                                            <TextField defaultValue={(this.state.selectedSKU !== null && this.state.selectedSKU.hasOwnProperty("ingredientes") && this.state.selectedSKU.ingredientes) ? this.state.selectedSKU.ingredientes.join() : ""} onChange={event => this.setState({ingredientes: event.target.value})} id={"ingredientes"} name={"ingredientes"} label={"Ingredientes. Ej: Pan, Jamón, Queso"}/>
                                            <hr/>
                                            <div>
                                                <h3>Costo y Precio</h3>
                                                <Button onClick={(event) => this.setState({editCostPrice: true})}>Editar</Button>
                                            </div>

                                            {(!this.state.editCostPrice && this.state.cost) &&
                                                <div>

                                                    <p>Costo bruto:</p>
                                                    {this.state.cost.map((cost) => (
                                                        <div>
                                                            <p>{cost.supplier.name}</p>
                                                            <p>{cost.cost}</p>
                                                        </div>))}
                                                </div>
                                                }
                                            {this.state.editCostPrice && (
                                                <div>
                                                <Button onClick={this.addSupplierDropdown}>+</Button>
                                                    {this.state.cost.map((cost, index) => (
                                                    <div>
                                                        <FormControl className={"supplier-drop"}>
                                                            <InputLabel id={"supplier-label"}>
                                                                Proveedor
                                                            </InputLabel>
                                                            <Select value={cost['supplier']['_id']['$oid']} onChange={(event) => this.setSupplier(index, event.target.value)} labelId={"supplier-label"} id={"supplier-select"}>
                                                                {this.state.suppliers.filter((supplier) => this.filterSupplier(index, supplier, cost.supplier)).map((supplier) => (
                                                                    <MenuItem value={supplier["_id"]["$oid"]}>
                                                                        {supplier.name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                        <TextField label={"Costo bruto"} placeholder={"Costo bruto"} value={cost.cost} onChange={(event) => this.editCost(index, event.target.value)}/>
                                                        <Button onClick={(event) => this.removeCost(index)}>Eliminar</Button>
                                                    </div>
                                                ))}</div>)}
                                            {(!this.state.editCostPrice && this.state.price) &&
                                                <p>Precio bruto: {this.state.price}</p>}
                                            {this.state.editCostPrice &&
                                                <TextField label={"Precio bruto"} placeholder={"Precio bruto"} value={this.state.price} onChange={(event) => this.setState({price: event.target.value})}/>
                                            }
                                            <hr/>
                                            <div>
                                                <h3>Familia</h3>
                                                <Button onClick={(event) => this.setState({editFamily: true})}>
                                                    Editar
                                                </Button>
                                            </div>
                                            {(!this.state.editFamily && this.state.family) &&
                                                <p>{this.state.family.name}</p>}
                                            {this.state.editFamily &&
                                                <FormControl>
                                                <InputLabel id={"family-drop"}>
                                                    Familia
                                                </InputLabel>
                                                <Select labelId={"family-drop"} value={this.state.family} onChange={(event) => this.setState({family: event.target.value})}>
                                                    {this.state.families.map((family) => (
                                                        <MenuItem value={family["_id"]["$oid"]}>
                                                            {family.name}
                                                        </MenuItem>
                                                        ))}
                                                </Select>
                                            </FormControl>}
                                            <hr/>
                                            <h3>Tiendas disponibles</h3>
                                            <FormGroup>
                                                {this.state.loginUser.role === "superadmin" &&
                                                    this.state.stores.map(store =>
                                                    (<FormControlLabel control={<Checkbox onChange={event => this.toggleBlacklist(store, event.target.checked)} checked={this.state.blacklist.find(s => s["_id"]["$oid"] === store["_id"]["$oid"]) === undefined}/>} label={store.name}/>)
                                                )}
                                                {this.state.loginUser.role === "storechief" &&
                                                    <FormControlLabel control={<Checkbox
                                                        onChange={event => this.toggleBlacklist(this.state.loginUser.store, event.target.checked)}
                                                        checked={this.state.blacklist.find(s => s["_id"]["$oid"] === this.state.loginUser.store["_id"]["$oid"]) === undefined}/>}
                                                                      label={this.state.loginUser.store.name}/>
                                                }
                                            </FormGroup>
                                            <hr/>
                                            <div>
                                                <h3>Stock mínimo</h3>
                                                <Button onClick={(event) => this.setState({editStockMin: true})}>Editar</Button>
                                            </div>

                                            {(!this.state.editStockMin && this.state.stockMin) &&
                                                <p>{this.state.stockMin}</p>}
                                            {this.state.editStockMin &&
                                                <TextField value={this.state.stockMin} onChange={(event) => this.setState({stockMin: event.target.value})}/>
                                            }
                                            <hr/>
                                            <div>
                                                <h3>Stock máximo</h3>
                                                <Button onClick={(event) => this.setState({editStockMax: true})}>Editar</Button>
                                            </div>

                                            {(!this.state.editStockMax && this.state.stockMax) &&
                                                <p>{this.state.stockMax}</p>}
                                            {this.state.editStockMax &&
                                                <TextField value={this.state.stockMax} onChange={(event) => this.setState({stockMax: event.target.value})}/>
                                            }
                                            <hr/>
                                            <h3>Impuestos aplicables al SKU</h3>
                                            <FormGroup>
                                                {this.state.loginUser.role === "superadmin" &&
                                                    this.state.taxes.map((tax) => (
                                                        <FormControlLabel control={<Checkbox
                                                        onChange={event => this.toggleTaxes(tax, event.target.checked)}
                                                        checked={this.state.selectedTaxes.find(s => s["_id"]["$oid"] === tax["_id"]["$oid"]) !== undefined}/>}
                                                                      label={tax.name}/>)
                                                    )
                                                }
                                                {this.state.loginUser.role === "storechief" &&
                                                    this.state.selectedTaxes.map((tax) => (
                                                        <p>{tax.name}</p>
                                                    ))
                                                }
                                            </FormGroup>
                                            <hr/>
                                            <h3>Autocomanda</h3>
                                            <FormGroup>
                                                <FormControlLabel  control={<Checkbox onChange={(event) => this.setState({extra: event.target.checked})} checked={this.state.extra}/>} label={"Extra"}/>

                                            </FormGroup>
                                            <TextField placeholder={"Descuento autocomanda"} value={this.state.selfOrderDiscount} onChange={(event) => this.setState({selfOrderDiscount: event.target.value})}/>
                                        </Box>
                                    </div>
                                </div>}
                            {(this.state.selectedOption === "Cajas" && this.state.create) &&
                                <div>
                                    <div className={"new-sku-button"}>
                                        <h1>{(this.state.selectedCashRegister) ? "Editar caja" : "Crear caja"}</h1>
                                        <Box component={"form"} noValidate>
                                            <TextField defaultValue={(this.state.selectedCashRegister !== null) ? this.state.selectedCashRegister.name : ""} onChange={event => this.setState({name: event.target.value})} id={"name"} name={"name"} label={"Nombre de la caja"}/>
                                            <FormControl>
                                                <InputLabel id={"store-label"}>
                                                    Tienda
                                                </InputLabel>
                                                <Select onChange={(event) => this.setState({store: event.target.value})} value={this.state.store} label={"Tienda"} labelId={"store-label"} id={"store-select"}>
                                                    {this.state.stores.map((store) => (
                                                        <MenuItem value={store["_id"]["$oid"]}>
                                                            {store.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Button onClick={(event) => this.createCashRegister(event, this.state.selectedCashRegister !== null)}>{(this.state.selectedCashRegister) ? "Editar" : "Crear"}</Button>
                                        </Box>
                                    </div>
                                </div>}
                            {(this.state.selectedOption === "Movimientos de efectivo" && this.state.create) &&
                                <div>
                                    <div className={"new-sku-button"}>
                                        <h1>{(this.state.selectedCashMoveType) ? "Editar movimiento efectivo" : "Crear movimiento de efectivo"}</h1>
                                        <Box className={"create-cash-move"} component={"form"} noValidate>
                                            <TextField className={"create-cash-move"} defaultValue={(this.state.selectedCashMoveType!== null) ? this.state.selectedCashMoveType.name : ""} onChange={event => this.setState({name: event.target.value})} id={"name"} name={"name"} label={"Nombre del movimiento"}/>
                                            <FormControl className={"create-cash-move"}>
                                                <InputLabel id={"store-label"}>
                                                    Tipo
                                                </InputLabel>
                                                <Select className={"create-cash-move-type"} onChange={(event) => this.setState({cashMoveType: event.target.value})} value={this.state.cashMoveType} label={"Tipo"} labelId={"type-label"} id={"type-select"}>
                                                    <MenuItem value={"Ingreso"}>Ingreso</MenuItem>
                                                    <MenuItem value={"Egreso"}>Egreso</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <Button className={"create-cash-move"} onClick={(event) => this.createCashMoveType(event, this.state.selectedCashMoveType !== null)}>{(this.state.selectedCashMoveType) ? "Editar" : "Crear"}</Button>
                                        </Box>
                                    </div>
                                </div>}
                            {(this.state.selectedOption === "Usuarios" && this.state.create) &&
                                <div>
                                    <div className={"new-sku-button"}>
                                        <h1>{(this.state.selectedUser) ? "Editar usuario" : "Crear usuario"}</h1>
                                        <Box component={"form"} noValidate>
                                            <TextField defaultValue={(this.state.selectedUser !== null) ? this.state.selectedUser.name : ""} onChange={event => this.setState({name: event.target.value})} id={"name"} name={"name"} label={"Nombre y apellido"}/>
                                            <TextField defaultValue={(this.state.selectedUser !== null) ? this.state.selectedUser.email : ""} onChange={event => this.setState({email: event.target.value})} id={"email"} name={"email"} label={"Email"}/>
                                            <TextField defaultValue={(this.state.selectedUser !== null) ? this.state.selectedUser.password : ""} onChange={event => this.setState({password: event.target.value})} id={"password"} name={"password"} label={"Contraseña"}/>
                                            <FormControl>
                                                <FormLabel>Permisos</FormLabel>
                                                <RadioGroup name={"role"} defaultValue={(this.state.selectedUser !== null) ? this.state.selectedUser.role : "storechief"}>
                                                    <FormControlLabel value={"superadmin"} control={<Radio onChange={(event) => this.setState({role: event.target.value})}/>} label={"SuperAdmin"}/>
                                                    <FormControlLabel value={"storechief"} control={<Radio onChange={(event) => this.setState({role: event.target.value})}/>} label={"Jefe de sucursal"}/>
                                                    <FormControlLabel value={"cashier"} control={<Radio onChange={(event) => this.setState({role: event.target.value})}/>} label={"Cajero"}/>
                                                </RadioGroup>
                                            </FormControl>
                                            <FormControl>
                                                <InputLabel id={"company-label"}>
                                                    Empresa
                                                </InputLabel>
                                                <Select onChange={(event) => this.setState({selectedCompany: event.target.value})} value={this.state.selectedCompany} label={"Empresa"} labelId={"company-label"} id={"company-select"}>
                                                    {this.state.companies.map((company) => (
                                                    <MenuItem value={company["_id"]["$oid"]}>
                                                        {company.name}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Button onClick={(event) => this.createUser(event, this.state.selectedUser !== null)}>{(this.state.selectedUser) ? "Editar" : "Crear"}</Button>
                                        </Box>
                                    </div>
                                </div>}
                            {this.state.addSKU &&
                            <div className={"filtered-skus-table-container"}>
                                <Button onClick={() => this.setState({addSKU: false})}>Atrás</Button>
                                <TextField value={this.state.searchSKU} onChange={(event) => this.setState({searchSKU: event.target.value})}/>
                                <Button onClick={() => this.filterSKUs()}>Buscar</Button>
                                <table className={"filtered-skus-table"}>
                                    <tr>
                                        <th>Foto</th>
                                        <th>Nombre</th>
                                        <th>Código interno</th>
                                    </tr>
                                    {this.state.filteredSKUs.map((sku) => (
                                        <tr className={"filtered-skus-row"}>
                                            <td className={"filtered-skus-cell"}>
                                                <img className={"sku-image-admin"} src={sku.url}/>
                                            </td>
                                            <td className={"filtered-skus-cell"}>{sku.sku_name}</td>
                                            <td className={"filtered-skus-cell"}>{sku.codigo_interno}</td>
                                            <td><Button onClick={(event) => this.addToCatalog(sku)}>Agregar</Button>
                                            </td>
                                            <td><Button onClick={(event) => this.deleteSKU(sku)}>Borrar</Button>
                                            </td>
                                        </tr>))}
                                </table>
                            </div>
                            }
                            {(this.state.selectedOption === "SKUs" && !this.state.create) &&
                                <div className={"filtered-skus-table-container"}>
                                    <div style={{display: "flex"}}>
                                        <div>
                                            <TextField value={this.state.searchSKU} onChange={(event) => this.setState({searchSKU: event.target.value})}/>
                                            <Button onClick={() => this.filterSKUsAll()}>Buscar</Button>
                                            <Button onClick={() => this.pageSkus(1)}>Limpiar</Button>
                                        </div>
                                        <div style={{marginLeft: "auto", marginRight: "auto"}}>
                                            <FormControl style={{width: 200}}>
                                                <InputLabel id={"supplier-label"}>Proveedor</InputLabel>
                                                <Select labelId={"suppler-label"} id={"supplier-dropdown"} value={this.state.supplierDropdown} label={"Proveedor"} onChange={(event) => {this.setState({supplierDropdown: event.target.value}); this.filterSKUs(event.target.value, this.state.familyDropdown)}}>
                                                {this.state.suppliers.map((supplier) => (
                                                    <MenuItem value={supplier["_id"]["$oid"]} >
                                                        {supplier.name}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                                <Button onClick={(event) => {this.setState({supplierDropdown: null}); this.filterSKUs()}}>Limpiar</Button>
                                            </FormControl>
                                            <FormControl style={{width: 200}}>
                                                <InputLabel id={"family-label"}>Familia</InputLabel>
                                                <Select labelId={"family-label"} id={"family-dropdown"} value={this.state.familyDropdown} label={"Familia"} onChange={(event) => {this.setState({familyDropdown: event.target.value}); this.filterSKUs(this.state.supplierDropdown, event.target.value)}}>
                                                    {this.state.families.map((family) => (
                                                        <MenuItem value={family["_id"]["$oid"]} >
                                                            {family.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <Button onClick={(event) => {this.setState({familyDropdown: null}); this.filterSKUs(this.state.supplierDropdown, null)}}>Limpiar</Button>
                                            </FormControl>
                                        </div>
                                        <div style={{marginLeft: "auto", marginRight: "auto"}}>
                                            <Button onClick={() => this.setState({create: true})}>Crear SKU</Button>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        {this.state.skuPage > 1 && <Button onClick={(e) => {this.pageSkus(this.state.skuPage - 1)}}>Atras</Button>}
                                        <p># Página: {this.state.skuPage}</p>
                                        {<Button onClick={(e) => {this.pageSkus(this.state.skuPage + 1)}}>Adelante</Button>}
                                    </div>
                                    <table className={"filtered-skus-table"}>
                                        <tr>
                                            <th>Código interno</th>
                                            <th>Nombre</th>
                                            <th>Precio bruto</th>
                                            <th>Costo bruto</th>
                                            <th>Familia</th>
                                        </tr>
                                        {this.state.filteredSKUs.map((sku, index) => (
                                            <tr className={"filtered-skus-row"}>
                                                <td className={"filtered-skus-cell"}>{sku.codigo_interno}</td>
                                                <td className={"filtered-skus-cell"}>{sku.sku_name}</td>
                                                <td className={"filtered-skus-cell"}>{(this.state.bulkPriceEdit === index) ? <TextField onChange={(event) => this.setState({price: event.target.value})} value={this.state.price} placeholder={"Precio"} /> : sku.precio}<IconButton onClick={(event) => {(this.state.bulkPriceEdit === index) ? this.createSKU(event, true) : this.setState({bulkPriceEdit: index, price: sku.price, selectedSKU: sku})}}><EditIcon/></IconButton></td>
                                                <td className={"filtered-skus-cell"}>{(this.state.bulkCostEdit === index) ? <TextField onChange={(event) => this.setState({cost: event.target.value})} value={this.state.cost} placeholder={"Costo"} /> : sku.cost.map((cost) => (<p>{cost.cost}</p>))}{sku.cost.length < 2 && <IconButton onClick={(event) => {(this.state.bulkCostEdit === index) ? this.createSKU(event, true) : this.setState({bulkCostEdit: index, cost: sku.cost, selectedSKU: sku})}}><EditIcon/></IconButton>}</td>
                                                <td className={"filtered-skus-cell"}>{sku.family?.name || ''}</td>
                                                <td>
                                                    <Button onClick={() => this.setState({selectedSKU: sku, selfOrderDiscount: sku.discount, extra: sku.extra, create: true, family: sku.family, stockMin: sku.stockMin, stockMax: sku.stockMax, selectedTaxes: sku.taxes, cost: sku.cost, price: sku.precio, codigo_interno: sku.codigo_interno, sku_name: sku.sku_name})}>Editar</Button>
                                                </td>
                                                <td><Button onClick={(event) => this.deleteSKU(sku)}>Borrar</Button>
                                                </td>
                                            </tr>))}
                                    </table>
                                </div>
                            }
                            {(this.state.selectedOption === "Inventario" && !this.state.create) &&
                                <div>
                                    <FormControl>
                                        <InputLabel id={"list-stores-inventory"}>Tienda</InputLabel>
                                        <Select label={"Tienda"} labelId={"list-stores-inventory"} value={this.state.selectedStore} onChange={(event) => {this.setState({selectedStore: event.target.value}); this.stocksByStore(event.target.value)}}>
                                            {this.state.stores.map((store) => (
                                                <MenuItem value={store["_id"]["$oid"]}>{store.name}</MenuItem>
                                        ))}
                                        </Select>
                                    </FormControl>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Fecha</TableCell>
                                                <TableCell>Nombre del inventariador o ajustador</TableCell>
                                                <TableCell>Código interno</TableCell>
                                                <TableCell>Nombre del SKU</TableCell>
                                                <TableCell>Conteo exhibición</TableCell>
                                                <TableCell>Conteo almacén</TableCell>
                                                <TableCell>Total conteo real</TableCell>
                                                <TableCell>Stock en sistema</TableCell>
                                                <TableCell>Monto a ajustar o ajustado</TableCell>
                                                <TableCell>Modo de defecto de diferencia de inventario</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.stocks.filter((stock) => stock.hasOwnProperty("shelfStock") || stock.hasOwnProperty("defectMode")).map((stock, rowIndex) => (
                                                <TableRow>
                                                    <TableCell>{stock.date}</TableCell>
                                                    <TableCell>{stock.user.name}</TableCell>
                                                    <TableCell>{(stock.sku) ? stock.sku.codigo_interno : ""}</TableCell>
                                                    <TableCell>{(stock.sku) ? stock.sku.sku_name: ""}</TableCell>
                                                    <TableCell>{stock.shelfStock}</TableCell>
                                                    <TableCell>{stock.warehouseStock}</TableCell>
                                                    <TableCell>{(stock.hasOwnProperty("shelfStock")) ? parseInt(stock.shelfStock) + parseInt(stock.warehouseStock): ""}</TableCell>
                                                    <TableCell>{stock.systemStock}</TableCell>
                                                    <TableCell>{(stock.hasOwnProperty("amount")) ? stock.amount : parseInt(stock.shelfStock) + parseInt(stock.warehouseStock)-stock.systemStock}</TableCell>
                                                    <TableCell>{(stock.hasOwnProperty("defectMode")) ? stock.defectMode : ""}</TableCell>
                                                    <TableCell>
                                                        {!stock.hasOwnProperty("defectMode") &&
                                                            <Button onClick={(event) => this.setState({selectedStock: stock})}>
                                                            Ajustar
                                                        </Button>}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    {this.state.selectedStock !== null &&
                                    <div>
                                        <h1>Crear Ajuste</h1>
                                        <TextField placeholder={"Monto a ajustar negativo egreso y positivo ingreso"} value={this.state.chiefAdjustment} onChange={(event) => this.setState({chiefAdjustment: event.target.value})}/>
                                        <FormControl>
                                            <InputLabel id={"modo-defecto-ajuste"}>
                                                Modo de defecto de ajuste
                                            </InputLabel>
                                            <Select label={"Modo de defecto de ajuste"} labelId={"modo-defecto-ajuste"} value={this.state.defectMode} onChange={(event) => this.setState({defectMode: event.target.value})}>
                                                {this.state.defectModes.map((defectMode) =>(
                                                    <MenuItem value={defectMode._id["$oid"]}>
                                                        {defectMode.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Button onClick={(event) => this.adjustStock()}>
                                            Ajustar
                                        </Button>
                                    </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default Admin;