import React from "react";
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    FormControlLabel, Checkbox, Grid
} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers"
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

class CreateObject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {name: null, rut: null, address: null, phone: null, email: null, barcode: null, skus: {}, search_sku: '', sku_results: [], comboCombinations: []};
        let states = {};
        for (let index in this.props.inputs) {
            if (this.props.container.state[`selected${this.props.name}`]) {
                if (this.props.inputs[index].hasOwnProperty("type") && this.props.inputs[index].type === "date") {
                    states[`create_${this.props.inputs[index].name}`] = dayjs(this.props.container.state[`selected${this.props.name}`][this.props.inputs[index].name])
                }
                else if (this.props.inputs[index].hasOwnProperty("type") && this.props.inputs[index].type === "priceTable") {
                    this.combinations(this.props.container.state[`selected${this.props.name}`]['objectGroups']);
                    states[`create_${this.props.inputs[index].name}`] = this.props.container.state[`selected${this.props.name}`][this.props.inputs[index].name]
                }
                else {
                    states[`create_${this.props.inputs[index].name}`] = this.props.container.state[`selected${this.props.name}`][this.props.inputs[index].name]
                }
            }
            else {
                if (this.props.inputs[index].hasOwnProperty("type") && (this.props.inputs[index].type === "SKU" || this.props.inputs[index].type === "group" || this.props.inputs[index].type === "store") || (this.props.inputs[index].type === "family") || (this.props.inputs[index].type === "customer") || (this.props.inputs[index].type === "day") || (this.props.inputs[index].type === "barcode")) {
                    states[`create_${this.props.inputs[index].name}`] = [];
                }
                else if (this.props.inputs[index].hasOwnProperty("type") && this.props.inputs[index].type === "priceTable") {
                    states[`create_${this.props.inputs[index].name}`] = {};
                } else if (this.props.inputs[index].hasOwnProperty("type") && this.props.inputs[index].type === "date") {
                    states[`create_${this.props.inputs[index].name}`] = dayjs(Date.now());
                } else if (this.props.inputs[index].hasOwnProperty("type") && this.props.inputs[index].type === "user") {
                    states[`create_${this.props.inputs[index].name}`] = this.props.inputs[index].value;
                }

            }
        }
        this.props.container.setState(states);
        this.addObject = this.addObject.bind(this);
        this.deleteObject= this.deleteObject.bind(this);
        this.toggleStore = this.toggleStore.bind(this);
        this.toggleDay = this.toggleDay.bind(this);
        this.combinations = this.combinations.bind(this);
        this.editIndex = this.editIndex.bind(this);
        this.findSupplier = this.findSupplier.bind(this);
        this.findBarcode = this.findBarcode.bind(this);
        this.searchSku = this.searchSku.bind(this);
    }

    componentDidMount() {
        fetch("https://apps.getitchile.cl/api/skus?barcode=true").then(result => result.json()).then(data => this.setState({skus: data}));
    }

    addObject(newValue, name) {
        let currentObjects = this.props.container.state[name];
        currentObjects.push(newValue);
        this.props.container.setState({[name]: currentObjects})
    }

    deleteObject(index, name) {
        let currentObjects = this.props.container.state[name];
        currentObjects.splice(index, 1);
        this.props.container.setState({[name]: currentObjects})
    }

    toggleStore(store, checked, inputName) {
        let blacklist = this.props.container.state[inputName];
        let element = blacklist.find(s => s === store["_id"]["$oid"]);
        let index = (element !== undefined) ? blacklist.indexOf(element) : -1;
        if (checked) {
            if (index !== -1) {
                blacklist.splice(index, 1)
            }
        }
        else {
            if (index === -1) {
                blacklist.push(store["_id"]["$oid"])
            }
        }
        this.props.container.setState({[inputName]: blacklist})
    }

    toggleDay(day, input) {
        let blacklist = this.props.container.state[input];
        if (blacklist.includes(day)) {
            blacklist.splice(blacklist.indexOf(day), 1)
        }
        else {
            blacklist.push(day)
        }
        this.setState({[input]: blacklist})
    }

    combinations(collection) {
        let group_ids = collection.map((group) => group._id["$oid"]).join(",");
        fetch('https://apps.getitchile.cl/api/comboCombinations?groups='+group_ids)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => this.setState({comboCombinations: data}))
            .catch(error => {
                console.error('Fetch error:', error);
            });
    }

    editIndex(index, value, skus, inputName) {
        let values = this.props.container.state[inputName] || {};
        values[index] = {price: value, skus: skus};
        this.props.container.setState({[inputName] : values})
    }

    findSupplier(rut, suppliers) {
        let supplier = suppliers.find((s) => s.rut === rut);
        if (supplier !== undefined) {
            this.setState({
                name: supplier.name,
                rut: supplier.rut,
                address: supplier.address,
                phone: supplier.phone,
                email: supplier.email
            })
        } else {
            alert("Ese RUT no se encuentra registrado");
        }
    }

    findBarcode(barcode, inputName) {
        console.log(this.state.skus);
        if (this.state.skus.hasOwnProperty(barcode)) {
            let objectSKUs = this.props.container.state[inputName];
            let exists = objectSKUs.find((s) => s["barra"] === barcode);
            if (exists === undefined) {
                objectSKUs.push(this.state.skus[barcode]);
                this.props.container.setState({[inputName]: objectSKUs});
                this.setState({barcode: null})
            } else {
                alert("Este SKU ya fue agregado");
            }
        } else {
            this.setState({barcode: barcode})
        }
    }

    searchSku(search, inputName) {
        fetch(`https://apps.getitchile.cl/api/skus?search=${search}`).then(result => result.json()).then(data => {this.setState({[`${inputName}_results`]: data})});
    }

    render() {
        return (
            <div>
                <div className={"new-sku-button"}>
                    <h1>{(this.props.container.state[`selected${this.props.name}`]) ? `Editar ${this.props.title}`  : `Crear ${this.props.title}`}</h1>
                    <Box component={"form"} noValidate>
                        {this.props.inputs.map((input) => (
                            <div>
                                {(!input.hasOwnProperty("type")) && <TextField label={`create_${input.name}`} placeholder={(input.hasOwnProperty("placeholder")) ? input.placeholder : `create_${input.name}`} value={this.props.container.state[`create_${input.name}`]} onChange={(event) => this.props.container.setState({[`create_${input.name}`]: event.target.value})}/>}
                                {(input.hasOwnProperty("type") && input.type === "SKU" && this.props.container.state[`create_${input.name}`] !== undefined) && (
                                    <div>
                                        <TextField value={this.state[`search_${input.name}`]} onChange={(e) => this.setState({[`search_${input.name}`]: e.target.value})}/>
                                        <Button onClick={(e) => this.searchSku(this.state[`search_${input.name}`], input.name)}>
                                            Buscar
                                        </Button>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        {input.searchTitle}
                                                    </TableCell>
                                                    <TableCell>
                                                        Acciones
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.hasOwnProperty(`${input.name}_results`) && this.state[`${input.name}_results`].map((sku) => (
                                                    <TableRow>
                                                        <TableCell>
                                                            {sku.sku_name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button onClick={(event) => this.addObject(sku, `create_${input.name}`)}>
                                                                Agregar
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>{input.title}</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.container.state[`create_${input.name}`].map((sku, index) => (
                                                    <TableRow>
                                                        <TableCell>{sku.sku_name}</TableCell>
                                                        <TableCell><Button onClick={(event) => this.deleteObject(index, `create_${input.name}`)}>Borrar</Button></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>

                                )}
                                {(input.hasOwnProperty("type") && input.type === "SKUCode") && (
                                    <div>
                                        <Autocomplete onChange={(event, newValue) => this.props.container.setState({[`create_${input.name}`]: newValue.sku})} renderInput={(params) => <TextField {...params} label={"Escribe un SKU"}/>} options={this.props.container.state.skus.map((sku) => {return {label: sku.sku_name, sku: sku}})}/>
                                    </div>

                                )}
                                {(input.hasOwnProperty("type") && input.type === "group" && this.props.container.state[`create_${input.name}`] !== undefined) && (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel id={"group-label"}>
                                                Grupos
                                            </InputLabel>
                                            <Select onChange={(event) => this.addObject(event.target.value, `create_${input.name}`)} labelId={"group-label"} id={"group-drop"} label={"Grupos"}>
                                                {this.props.container.state.groups.filter((group) =>  this.props.container.state[`create_${input.name}`].find((selected) => selected === group["_id"]["$oid"]) === undefined).map((group) => (
                                                    <MenuItem value={group["_id"]["$oid"]}>
                                                        {group.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Grupos</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.container.state[`create_${input.name}`].map((group, index) => (
                                                    <TableRow>
                                                        <TableCell>{group.name}</TableCell>
                                                        <TableCell><Button onClick={(event) => this.deleteObject(index, `create_${input.name}`)}>Borrar</Button></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                )}
                                {(input.hasOwnProperty("type") && input.type === "family" && this.props.container.state[`create_${input.name}`] !== undefined) && (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel id={"family-label"}>
                                                Familias excluidas
                                            </InputLabel>
                                            <Select onChange={(event) => this.addObject(event.target.value, `create_${input.name}`)} labelId={"family-label"} id={"family-drop"} label={"Familias"}>
                                                {this.props.container.state.families.filter((family) =>  this.props.container.state[`create_${input.name}`].find((selected) => selected === family["_id"]["$oid"]) === undefined).map((family) => (
                                                    <MenuItem value={family["_id"]["$oid"]}>
                                                        {family.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Familias excluidas</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.container.state[`create_${input.name}`].map((family, index) => (
                                                    <TableRow>
                                                        <TableCell>{this.props.container.state.families.find((item) => item["_id"]["$oid"] === family).name}</TableCell>
                                                        <TableCell><Button onClick={(event) => this.deleteObject(index, `create_${input.name}`)}>Borrar</Button></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                )}
                                {(input.hasOwnProperty("type") && input.type === "customer" && this.props.container.state[`create_${input.name}`] !== undefined && (input.hasOwnProperty("condition") && !this.props.container.state[input.condition])) && (
                                    <div>
                                        <FormControl fullWidth>
                                            <InputLabel id={"customer-label"}>
                                                Clientes
                                            </InputLabel>
                                            <Select onChange={(event) => this.addObject(event.target.value, `create_${input.name}`)} labelId={"customer-label"} id={"customer-drop"} label={"Clientes"}>
                                                {this.props.container.state.customers.filter((customer) =>  this.props.container.state[`create_${input.name}`].find((selected) => selected === customer["_id"]["$oid"]) === undefined).map((customer) => (
                                                    <MenuItem value={customer["_id"]["$oid"]}>
                                                        {customer.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Clientes</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.container.state[`create_${input.name}`].map((customer, index) => (
                                                    <TableRow>
                                                        <TableCell>{this.props.container.state.customers.find((item) => item["_id"]["$oid"] === customer).name}</TableCell>
                                                        <TableCell><Button onClick={(event) => this.deleteObject(index, `create_${input.name}`)}>Borrar</Button></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                )}
                                {(input.hasOwnProperty("type") && input.type === "date") &&
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label={`create_${input.name}`} onChange={(date) => this.props.container.setState({[`create_${input.name}`]: date})} label={`create_${input.name}`} value={this.props.container.state[`create_${input.name}`]} renderInput={(params) => <TextField {...params}/>}/>
                                    </LocalizationProvider>
                                    }
                                {(input.hasOwnProperty("type") && input.type === "day" && this.props.container.state[`create_${input.name}`] !== undefined) &&
                                    <div>
                                        <hr/>
                                        <h3>Días aplicables</h3>
                                        {["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"].map((day) => <FormControlLabel control={<Checkbox onChange={(event) => this.toggleDay(day, `create_${input.name}`)} checked={!this.props.container.state[`create_${input.name}`].includes(day)}/>} label={day}/>)}
                                    </div>
                                        }
                                {(input.hasOwnProperty("type") && input.type === "checkbox") &&
                                    <FormControlLabel control={<Checkbox checked={this.props.container.state[`create_${input.name}`]} onChange={(event) => this.props.container.setState({[`create_${input.name}`]: event.target.checked})}/>} label={input.placeholder}/>
                                }
                                {(input.hasOwnProperty("type") && input.type === "priceTable") &&
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>SKUs</TableCell>
                                                <TableCell>Precio original</TableCell>
                                                <TableCell>Costo bruto</TableCell>
                                                <TableCell>Margen actual</TableCell>
                                                <TableCell>Precio combo</TableCell>
                                                <TableCell>Margen combo</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Object.keys(this.state.comboCombinations).map((combination) => (
                                                <TableRow>
                                                    <TableCell>{this.state.comboCombinations[combination].map((sku) => <p>{sku.sku_name}</p>)}</TableCell>
                                                    <TableCell>{this.state.comboCombinations[combination].reduce((acumulator, sku) => acumulator + parseInt(sku.precio), 0)}</TableCell>
                                                    <TableCell>{this.state.comboCombinations[combination].reduce((acumulator, sku) => acumulator + parseInt(sku.cost[0]?.cost), 0)}</TableCell>
                                                    <TableCell>{"" + ((this.state.comboCombinations[combination].reduce((acumulator, sku) => acumulator + parseInt(sku.precio), 0) - this.state.comboCombinations[combination].reduce((acumulator, sku) => acumulator + sku.cost.reduce((a, c) => a + c.cost, 0), 0)) / this.state.comboCombinations[combination].reduce((acumulator, sku) => acumulator + parseInt(sku.precio), 0) * 100) + "%"}</TableCell>
                                                    <TableCell><TextField value={(this.props.container.state.hasOwnProperty(`create_${input.name}`) && this.props.container.state[`create_${input.name}`].hasOwnProperty(combination)) ? this.props.container.state[`create_${input.name}`][combination].price : ""} onChange={(event) => this.editIndex(combination, event.target.value, this.state.comboCombinations[combination].map((s) => s["_id"]["$oid"]), `create_${input.name}`)}/></TableCell>
                                                    <TableCell>{(this.props.container.state.hasOwnProperty(`create_${input.name}`) && this.props.container.state[`create_${input.name}`].hasOwnProperty(combination)) && "" + parseInt((parseInt(this.props.container.state[`create_${input.name}`][combination].price) - this.state.comboCombinations[combination].reduce((acumulator, sku) => acumulator + sku.cost.reduce((a, c) => a + c.cost, 0), 0)) / parseInt(this.props.container.state[`create_${input.name}`][combination].price) * 100.0) + "%"}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                }
                                {(input.hasOwnProperty("type") && input.type === "store" && this.props.container.state[`create_${input.name}`] !== undefined) &&
                                    <div>
                                        <hr/>
                                        <h3>Tiendas</h3>
                                        <Grid container>
                                        {this.props.container.state.stores.map(store =>
                                        (<Grid item>
                                            <FormControlLabel control={<Checkbox onChange={event => this.toggleStore(store, event.target.checked, `create_${input.name}`)} checked={this.props.container.state[`create_${input.name}`].find(s => s === store["_id"]["$oid"]) === undefined}/>} label={store.name}/>
                                        </Grid>
                                        ))}
                                        </Grid>
                                    </div>
                                }
                                {(input.hasOwnProperty("type") && input.type === "suppliers") &&
                                    <div>
                                        <TextField value={this.props.container.state[`create_${input.name}`]} onChange={(event) => this.props.container.setState({[`create_${input.name}`]: event.target.value})}/>
                                        <Button onClick={(event) => this.findSupplier(this.props.container.state[`create_${input.name}`], input.collection)}>Buscar proveedor</Button>
                                        <p>{this.state.name}</p>
                                        <p>{this.state.rut}</p>
                                        <p>{this.state.address}</p>
                                        <p>{this.state.phone}</p>
                                        <p>{this.state.email}</p>
                                    </div>
                                }
                                {(input.hasOwnProperty("type") && input.type === "user") &&
                                    <div>
                                        <p>{input.display}</p>

                                    </div>
                                }
                                {(input.hasOwnProperty("type") && input.type === "barcode" && this.props.container.state[`create_${input.name}`] !== undefined) &&
                                    <div>
                                        <TextField placeholder={"Código de barras"} value={this.state.barcode} onChange={(event) => this.findBarcode(event.target.value, `create_${input.name}`)}/>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Código de barras</TableCell>
                                                    <TableCell>Código interno</TableCell>
                                                    <TableCell>Nombre del SKU</TableCell>
                                                    <TableCell>Costo neto</TableCell>
                                                    <TableCell>Costo bruto</TableCell>
                                                    <TableCell>Cantidad</TableCell>
                                                    <TableCell>Subtotal</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.props.container.state[`create_${input.name}`].map((sku, index) => (
                                                    <TableRow>
                                                        <TableCell>{sku.barra}</TableCell>
                                                        <TableCell>{sku.codigo_interno}</TableCell>
                                                        <TableCell>{sku.sku_name}</TableCell>
                                                        <TableCell><TextField value={sku.invoiceNetCost} onChange={event => {this.props.container.state[`create_${input.name}`][index].invoiceNetCost = event.target.value; this.props.container.setState({[`create_${input.name}`]: this.props.container.state[`create_${input.name}`]})}}/></TableCell>
                                                        <TableCell><TextField value={sku.invoiceGrossCost} onChange={event => {this.props.container.state[`create_${input.name}`][index].invoiceGrossCost = event.target.value; this.props.container.setState({[`create_${input.name}`]: this.props.container.state[`create_${input.name}`]})}}/></TableCell>
                                                        <TableCell><TextField value={sku.invoiceQuantity} onChange={event => {this.props.container.state[`create_${input.name}`][index].invoiceQuantity = event.target.value; this.props.container.setState({[`create_${input.name}`]: this.props.container.state[`create_${input.name}`]})}}/></TableCell>
                                                        <TableCell>{parseInt(sku.invoiceQuantity) * parseInt(sku.invoiceGrossCost)}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <h1>Total: {this.props.container.state[`create_${input.name}`].reduce((accumulator, sku) => accumulator + parseInt(sku.invoiceQuantity) * parseInt(sku.invoiceGrossCost), 0)}</h1>
                                    </div>
                                }
                            </div>
                                ))}

                        <Button onClick={(event) => this.props.create(event, this.props.container.state[`selected${this.props.name}`] !== undefined && this.props.container.state[`selected${this.props.name}`] !== null, this.props.inputs, this.props.collection, this.props.object, this.props.name)}>{(this.props.container.state[`selected${this.props.name}`]) ? "Guardar" : "Crear"}</Button>
                    </Box>
                </div>
            </div>
        )
    }
}

export default CreateObject;