import React from "react";
import {
    Box,
    Button,
    TextField,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Autocomplete,
    FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import "./Sales.css";
import {sha256} from "js-sha256";
import cobrar from "./cobrar.png";
import search from "./search.png";
import withdraw from "./withdraw.png";
import saleWait from "./saleWait.png";
import discount from "./discount.png";
import shiftClose from "./shiftClose.png";
import loyalty from "./loyalty.png";
import sumButton from "./sumButton.png";
import minusButton from "./minusButton.png";
import getitLogo from "./getitLogo.png";
import NumericFormat from "react-number-format";
import SignatureCanvas from "react-signature-canvas";
import closeWindow from "./cancelEquis.png";
import {jsPDF} from "jspdf";


class Sales extends React.Component {
    constructor(props) {
        super(props);
        this.timer = setInterval(() => this.setState({currentDate: new Date().toLocaleString()}), 1000);
        this.signatureCanvas = null;
        this.rightPanelButtons = [{image: cobrar, action: this.charge}, {image: search, action: this.search}, {image: loyalty, action: this.loyalty}, {image: withdraw, action: this.cashMovement}, {image: saleWait, action: this.onHold}, {image: discount, action: this.discount}, {image: shiftClose, action: this.shiftClose}];
        this.state = {login: false, loginEmail: null, loginPassword: null, dialogOpen: false,
            dialogTitle: "", skus: {}, skuList: [], cart: [], quantities: {}, textField: "", textFieldSearch: "", bars: {}, categories: [], catalogs: [], selectedCatalog: null, paymentMethods: [],
        payment: 0, change: 0, total: 0, paymentField: "", partialPay: {}, loginUser: null, mac: null, shift: null, cashRegister: null, currentDate: new Date().toLocaleString(),
        availableReceipts: 0, availableInvoices: 0, availableNonTaxReceipts: 0, q20000: 0, q10000: 0, q5000: 0, q2000: 0, q1000: 0, q500: 0, q100: 0, q50: 0, q10: 0, otherPaymentDeclaration: false, goSignature: false,
        cashMoveType: null, cashMoveTypes: [], cashMoveQ: 0};
        this.login = this.login.bind(this);
        this.charge = this.charge.bind(this);
        this.search = this.search.bind(this);
        this.loyalty = this.loyalty.bind(this);
        this.cashMovement = this.cashMovement.bind(this);
        this.onHold = this.onHold.bind(this);
        this.discount = this.discount.bind(this);
        this.shiftClose = this.shiftClose.bind(this);
        this.addSKU = this.addSKU.bind(this);
        this.addQuantity = this.addQuantity.bind(this);
        this.partialCalculate = this.partialCalculate.bind(this);
        this.shiftCloseCash = this.shiftCloseCash.bind(this);
        this.shiftCloseOthers = this.shiftCloseOthers.bind(this);
        this.closeShift = this.closeShift.bind(this);
        this.findCode = this.findCode.bind(this);
        this.createTransaction = this.createTransaction.bind(this);
        this.createCashMove = this.createCashMove.bind(this);
    }

    componentDidMount() {
        fetch("https://apps.getitchile.cl/api/hashskus").then(response => response.json()).then(data => this.setState({skus: data}));
        fetch("https://apps.getitchile.cl/api/skus").then(response => response.json()).then(data => this.setState({skuList: data}));
        fetch("https://apps.getitchile.cl/api/hashbarcode").then(response => response.json()).then(data => this.setState({bars: data}));
        fetch("https://apps.getitchile.cl/api/categories").then(response => response.json()).then(data => this.setState({categories: data}));
        fetch("https://apps.getitchile.cl/api/catalogs").then(response => response.json()).then(data => this.setState({catalogs: data}));
        fetch("https://apps.getitchile.cl/api/paymentMethods").then(response => response.json()).then(data => this.setState({paymentMethods: data}));
        document.addEventListener("keydown", (event) => {if (event.key === "Enter" && this.state.dialogOpen) {event.preventDefault();
            this.setState({dialogOpen: false, cart: [], quantities: {}, partialPay: {}, paymentField:""})}});
        fetch("http://localhost:5000/mac").then(response => response.json()).then(data => this.setState({mac: data.mac}));
        fetch("https://apps.getitchile.cl/api/cashMoveTypes").then(response => response.json()).then(data => this.setState({cashMoveTypes: data}));
    }


    login(event) {
        let body = {email: this.state.loginEmail, password: sha256(this.state.loginPassword), mac: this.state.mac};
        fetch("https://apps.getitchile.cl/api/login", {method: "post", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.setState({login: data.status === "Ok", loginUser: data.user, shift: data.shift, cashRegister: data.cashRegister})})
        event.preventDefault();
    }



    charge(self) {
        let t = 0;
        for (const sku in self.state.cart) {
            t += self.state.cart[sku].precio * self.state.quantities[self.state.cart[sku].codigo_interno];
        }
        self.setState({dialogOpen: true, dialogTitle: "Cobrar", total: t});
    }

    search(self) {
        self.setState({dialogOpen: true, dialogTitle: "Buscar SKU"});
    }

    loyalty(self) {
        self.setState({dialogOpen: true, dialogTitle: "Fidelidad"});
    }

    cashMovement(self) {
        self.setState({dialogOpen: true, dialogTitle: "Retiros o Ingresos de efectivo"});
    }

    onHold() {
        console.log("Suspende la venta")
    }

    discount(self) {
        self.setState({dialogOpen: true, dialogTitle: "Descuentos"});
    }

    shiftClose(self) {
        self.setState({dialogOpen: true, dialogTitle: "Cierre de turno"});
    }

    findCode(name) {
        let sku = this.state.skuList.filter((n) => n.sku_name === name);
        console.log(this.state.skuList);
        console.log(name);
        let code = sku[0].codigo_interno;
        return code;
    }

    addSKU(code) {
        code = "" + code;
        let components = code.split("*");
        let quantity = (components.length > 1) ? parseInt(components[0]) : 1;
        let product = (components.length === 1) ? components[0] : components[1];
        if (this.state.skus.hasOwnProperty(product) || this.state.bars.hasOwnProperty(product)) {
            let cart = this.state.cart;
            let sku = cart.filter((item) => (item.barra) ? product === item.barra || product === item.codigo_interno : product === item.codigo_interno);
            if (sku.length > 0) {
                let quantities = this.state.quantities;
                quantities[sku[0].codigo_interno] += quantity;
                this.setState({quantities: quantities, textField: ""});
            } else {
                let sku = (this.state.skus.hasOwnProperty(product)) ? this.state.skus[product] : this.state.bars[product];
                cart.push(sku);
                let quantities = this.state.quantities;
                quantities[sku.codigo_interno] = quantity;
                this.setState({quantities: quantities, cart: cart, textField: "", dialogOpen: false});
            }
        }
    }

    addQuantity(code) {
        let q = this.state.quantities;
        q[code] += 1;
        this.setState({quantities: q});
    }

    substractQuantity(sku) {
        let q = this.state.quantities;
        q[sku.codigo_interno] -= 1;
        let c = this.state.cart;
        console.log(q[sku.codigo_interno]);
        if (q[sku.codigo_interno] === 0) {
            c.splice(c.indexOf(sku));
        }
        this.setState({quantities: q, cart: c});
    }

    partialCalculate(method) {
        let p = this.state.partialPay;
        p[method] = parseInt(this.state.paymentField)
        let pay = 0;
        for (const method in this.state.paymentMethods) {
            if (p.hasOwnProperty(this.state.paymentMethods[method].name)) {
                pay += p[this.state.paymentMethods[method].name]}
        }
        this.setState({payment: pay, change: pay - this.state.total, paymentField: "", partialPay: p})
    }

    shiftCloseCash() {
        return this.state.q20000 * 20000 + this.state.q10000 * 10000 + this.state.q5000 * 5000 + this.state.q2000 * 2000 + this.state.q1000 * 1000 + this.state.q500 * 500 + this.state.q100 * 100 + this.state.q50 * 50 + this.state.q10 * 10
    }

    shiftCloseOthers() {
        let result = this.state.paymentMethods.filter((method) => method.name !== "Efectivo").reduce((total, method) => {return total + parseInt(this.state[`textField${method.name}`])}, 0);
        return result
    }

    closeShift() {
        let signature = this.signatureCanvas.toDataURL("image/png");
        let url = "https://apps.getitchile.cl/api/uploadImage"
        let formData = new FormData()
        formData.append("image", signature.replace("data:image/png;base64,",""))
        formData.append("filename", this.state.shift["_id"]["$oid"] + ".png")
        let headers = {body: formData, method: "POST"}
        fetch(url, headers).then((response) => response.json()).then((response) => {
            let body =  this.state.shift;
            let date = new Date();
            body.close_time = date.toISOString();
            let methods = {};
            for (let index in this.state.paymentMethods) {
                let method = this.state.paymentMethods[index];
                if (method.name === "Efectivo") {
                    methods[method.name] = this.shiftCloseCash();
                } else {
                    methods[method.name] = this.state[`textField${method.name}`]
                }
            }
            body["paymentMethods"] = methods;
            body.signature = response.url;
            fetch("https://apps.getitchile.cl/api/shifts?shift_id=" + this.state.shift["_id"]["$oid"], {method: "PUT", body: JSON.stringify(body)}).then((result) => result.json()).then((data) =>{
                this.setState({dialogOpen: false, goSignature: false, loginEmail: null, loginPassword: null, loginUser: null, login: false})
                window.open(data.report, "_blank")
            })
        })
    }


    createTransaction() {
        let pay = 0;
        for (const method in this.state.paymentMethods) {
            if (this.state.partialPay.hasOwnProperty(this.state.paymentMethods[method].name)) {
                pay += this.state.partialPay[this.state.paymentMethods[method].name]}
        }
        if (pay >= this.state.total) {
            let date = new Date()
            let body = {date: `${date.getDay()}-${date.getMonth()}-${date.getFullYear()}`,
                time: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
                skus: this.state.cart.map((sku) => {return {_id: sku._id, name: sku.sku_name, taxes: sku.taxes, quantity: this.state.quantities[sku.codigo_interno], total: this.state.quantities[sku.codigo_interno] * sku.precio}}),
                cashRegister: this.state.cashRegister.name, cashier: this.state.loginUser, payment: this.state.partialPay, shift: this.state.shift["_id"]["$oid"], totalToCharge: this.state.total, totalPaid: this.state.payment, change: this.state.change}
            fetch("https://apps.getitchile.cl/api/transactions", {method: "POST", body: JSON.stringify(body)}).then((response) => response.json()).then((data) => this.setState({
                cart: [],
                quantities: {},
                partialPay: {},
                paymentField: "",
                payment: 0,
                change: 0,
                total: 0,
                dialogOpen: false,
                }
            ))
        }
        else {
            alert("Pago insuficiente o método de pago no seleccionado")
        }
    }

    createCashMove() {
        let type = this.state.cashMoveTypes.filter((t) => t["_id"]["$oid"] === this.state.cashMoveType)
        if (type.length > 0) {
            let body = {type: type[0], amount: this.state.cashMoveQ, shift: this.state.shift["_id"]["$oid"]}
            fetch("https://apps.getitchile.cl/api/cashMoves", {method: "POST", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then((response) => response.json()).then((data) => this.setState({dialogOpen: false}))
        } else {
            alert("No seleccionaste el concepto")
        }

    }

    render() {
        return (<div>
            {!this.state.login &&  <Box component={"form"} noValidate>
                <h1 className={"admin-title"}>Ingresa tus datos</h1>
                <div className={"field-div"}>
                    <TextField onChange={event => this.setState({loginEmail: event.target.value})} name={"email"} id={"email"} fullWidth={true} className={"admin-fields"} label={"email"}/>
                </div>
                <div className={"field-div"}>
                    <TextField onChange={event => this.setState({loginPassword: event.target.value})} name={"password"} id={"password"} type={"password"} fullWidth={true} className={"admin-fields"} label={"password"}/>
                </div>
                <Button className={"login-button"} onClick={this.login}>Iniciar sesión</Button>
            </Box>}
            {this.state.login &&
            <div id={"sales-view"}>
                <div id={"sales-left-panel"}>
                    <div id={"sales-left-top"}>
                        <div id={"sales-left-top-left"}>
                            <div>
                                <h2>{this.state.loginUser.company.name}</h2>
                            </div>
                            <div style={{display: "flex"}}>
                                <div>
                                    <img src={getitLogo} style={{width: 120, height: 120, objectFit: "contain"}}/>
                                </div>
                                <div>
                                    <h5>Dirección: {this.state.loginUser.company.tax_address}</h5>
                                    <h5>Giro: {this.state.loginUser.company.business_category}</h5>
                                    <h5>Sucursal: {this.state.loginUser.store.name}</h5>
                                    <h5>Apertura: {new Date(this.state.shift.open_time + "z").toLocaleString()}</h5>

                                </div>
                            </div>
                            <div style={{display: "flex"}}>
                                <div>
                                    <h5>{this.state.cashRegister.name}</h5>
                                    <h5>Cajero: {this.state.loginUser.name}</h5>
                                    <h5>{this.state.currentDate}</h5>
                                </div>
                                <div style={{marginLeft: "auto"}}>
                                    <h5>Boleta: {this.state.availableReceipts}</h5>
                                    <h5>Factura: {this.state.availableInvoices}</h5>
                                    <h5>Boleta exenta: {this.state.availableNonTaxReceipts}</h5>
                                </div>
                            </div>
                        </div>
                        <div id={"sales-left-top-right"}>
                            <div id={"sales-left-top-right-tabs"}>
                                {this.state.catalogs.map((catalog) => (
                                    <Button onClick={() => this.setState({selectedCatalog: catalog})}>{catalog.name}</Button>

                                ))}
                            </div>
                            <div id={"sales-left-top-right-skus"}>
                                {this.state.selectedCatalog !== null &&
                                <Grid container spacing={2}>
                                    {this.state.selectedCatalog.skus.map((sku) => (
                                        <Grid item onClick={() => {this.addSKU(this.state.textField + sku.codigo_interno)}} xs={2}><img className={"catalog-picture"} src={sku.url}/></Grid>
                                    ))}
                                </Grid>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <TextField variant={"outlined"} value={this.state.textField} onChange={(event) => {
                            this.setState({textField: event.target.value})
                            this.addSKU(event.target.value)
                        }}/>
                    </div>
                    <TableContainer id={"sales-left-bottom"}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={"cart-text-fields"}>Código</TableCell>
                                    <TableCell className={"cart-text-fields"}>Nombre</TableCell>
                                    <TableCell className={"cart-text-fields"}>Cantidad</TableCell>
                                    <TableCell className={"cart-text-fields"}>Precio</TableCell>
                                    <TableCell className={"cart-text-fields"}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.cart.map((sku) => (
                                    <TableRow key={sku.codigo_interno}>
                                        <TableCell className={"cart-text-fields"}>{sku.codigo_interno}</TableCell>
                                        <TableCell className={"cart-text-fields"}>{sku.sku_name}</TableCell>
                                        <TableCell className={"cart-text-fields"}><img className={"cart-sum-minus-buttons"} onClick={() => this.addQuantity(sku.codigo_interno)} src={sumButton}/>   {this.state.quantities[sku.codigo_interno]}   <img className={"cart-sum-minus-buttons"} onClick={() => this.substractQuantity(sku)} src={minusButton}/></TableCell>
                                        <TableCell className={"cart-text-fields"}>{sku.precio}</TableCell>
                                        <TableCell className={"cart-text-fields"}>{this.state.quantities[sku.codigo_interno] * sku.precio}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div id={"sales-right-panel"}>
                    {this.rightPanelButtons.map((button) => <img onClick={() => button.action(this)} className={"sales-right-panel-buttons"} src={button.image}/>)}
                </div>
                <Dialog fullWidth={true} maxWidth={"lg"} open={this.state.dialogOpen}>
                        <DialogTitle>
                            {this.state.dialogTitle}
                            <img src={closeWindow} width={40} height={40} onClick={() => this.setState({dialogOpen: false})}/>

                        </DialogTitle>
                        <DialogContent dividers>
                            {this.state.dialogTitle === "Cobrar" &&
                                (<div id={"payment-main"}>
                                    <div id={"payment-left"}>
                                        {this.state.paymentMethods.map((method) => (
                                            <div className={"method-main"}
                                                 onClick={() => this.partialCalculate(method.name)}>
                                                <div>
                                                    <img className={"method-image-sales"} src={method.url}/>
                                                    <h2>{method.name}</h2>
                                                </div>
                                                <div>
                                                    <h3>${this.state.partialPay[method.name]}</h3>
                                                </div>
                                            </div>

                                        ))}
                                    </div>
                                    <div>
                                        <TextField value={this.state.paymentField}
                                                   onChange={(event) => this.setState({paymentField: event.target.value})}/>
                                        <h1>Pago ${this.state.payment}</h1>
                                        <h1>Diferencia ${this.state.change}</h1>
                                        <h1>Total ${this.state.total}</h1>
                                    </div>
                                </div>)}
                            {this.state.dialogTitle === "Buscar SKU" &&
                                (<div className={"seach-by-name-sku"}>
                                        <div className={"seach-by-name-sku-div"}>
                                            <Autocomplete className={"seach-by-name-sku-autocomplete"}
                                                        onChange={(event) => this.setState({textFieldSearch: (event.target.firstChild !== undefined) ? event.target.firstChild.data : ""})}
                                                          renderInput={(params) => (
                                                              <TextField {...params}
                                                                         label={"Escribe el texto del SKU"}/>)}
                                                          options={this.state.skuList.map((sku) => sku.sku_name)}/>
                                            <Button className={"seach-by-name-sku-button"}
                                                    onClick={() => (this.state.textFieldSearch.length > 0)  ? this.addSKU(this.findCode(this.state.textFieldSearch)) : alert("Introduce un nombre de SKU")}>
                                                Agregar SKU
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            {(this.state.dialogTitle === "Cierre de turno" && this.state.otherPaymentDeclaration === false && this.state.goSignature === false) &&
                                (<div>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Denominación</TableCell>
                                                    <TableCell>Cantidad unidades</TableCell>
                                                    <TableCell>Monto a recaudar</TableCell>
                                                </TableRow>
                                            </TableHead>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell>20.000</TableCell>
                                                        <TableCell><TextField value={(this.state.q20000 === 0) ? "" : this.state.q20000} onChange={(event) => this.setState({q20000: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q20000 * 20000}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>10.000</TableCell>
                                                        <TableCell><TextField value={(this.state.q10000 === 0) ? "" : this.state.q10000} onChange={(event) => this.setState({q10000: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q10000 * 10000}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>5.000</TableCell>
                                                        <TableCell><TextField value={(this.state.q5000 === 0) ? "" : this.state.q5000} onChange={(event) => this.setState({q5000: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q5000 * 5000}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>2.000</TableCell>
                                                        <TableCell><TextField value={(this.state.q2000 === 0) ? "" : this.state.q2000} onChange={(event) => this.setState({q2000: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q2000 * 2000}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>1.000</TableCell>
                                                        <TableCell><TextField value={(this.state.q1000 === 0) ? "" : this.state.q1000}onChange={(event) => this.setState({q1000: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q1000 * 1000}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>500</TableCell>
                                                        <TableCell><TextField value={(this.state.q500 === 0) ? "" : this.state.q500} onChange={(event) => this.setState({q500: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q500 * 500}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>100</TableCell>
                                                        <TableCell><TextField value={(this.state.q100 === 0) ? "" : this.state.q100} onChange={(event) => this.setState({q100: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q100 * 100}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>50</TableCell>
                                                        <TableCell><TextField value={(this.state.q50 === 0) ? "" : this.state.q50} onChange={(event) => this.setState({q50: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q50 * 50}</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>10</TableCell>
                                                        <TableCell><TextField value={(this.state.q10 === 0) ? "" : this.state.q10} onChange={(event) => this.setState({q10: event.target.value})}/></TableCell>
                                                        <TableCell>{this.state.q10 * 10}</TableCell>
                                                    </TableRow>
                                                </TableBody>

                                        </Table>
                                    </TableContainer>
                                    <h1>Total: <NumericFormat displayType={"text"} thousandSeparator={"."} decimalSeparator={","} value={this.state.q20000 * 20000 + this.state.q10000 * 10000 + this.state.q5000 * 5000 + this.state.q2000 * 2000 + this.state.q1000 * 1000 + this.state.q500 * 500 + this.state.q100 * 100 + this.state.q50 * 50 + this.state.q10 * 10}/></h1>
                                </div>)
                            }
                            {(this.state.dialogTitle === "Cierre de turno" && this.state.otherPaymentDeclaration === true) &&
                                (
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Forma de pago</TableCell>
                                                    <TableCell>Monto a recaudar</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state.paymentMethods.filter((method) => method.name !== "Efectivo").map((method) => (
                                                    <TableRow>
                                                        <TableCell>{method.name}</TableCell>
                                                        <TableCell><TextField value={this.state[`textField${method.name}`]} onChange={(event) => this.setState({[`textField${method.name}`]: event.target.value})}/></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                            {this.state.goSignature && (
                                <div>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Forma de pago</TableCell>
                                                    <TableCell>Monto a recaudar</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Efectivo</TableCell>
                                                    <TableCell><NumericFormat displayType={"text"} thousandSeparator={"."} decimalSeparator={","} value={this.shiftCloseCash()}/></TableCell>
                                                </TableRow>
                                                {this.state.paymentMethods.filter((method) => method.name !== "Efectivo").map((method) => (
                                                    <TableRow>
                                                        <TableCell>{method.name}</TableCell>
                                                        <TableCell><NumericFormat displayType={"text"} thousandSeparator={"."} decimalSeparator={","} value={this.state[`textField${method.name}`]}/></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <h1>Total: <NumericFormat displayType={"text"} thousandSeparator={"."} decimalSeparator={","} value={this.shiftCloseCash() + this.shiftCloseOthers()}/></h1>
                                    <div style={{display: "flex"}}>
                                        <SignatureCanvas ref={(ref) => this.signatureCanvas = ref} backgroundColor={"rgba(255, 255, 255, 1)"} penColor={"black"} canvasProps={{width: 320, height: 320, className: "signature-field"}}/>
                                        <div>
                                            <h3>{this.state.loginUser.name}</h3>
                                            <h3>Fecha y hora de inicio: {this.state.shift.open_time}</h3>
                                            <h3>Fecha y hora de cierre: {this.state.currentDate}</h3>
                                            <Button onClick={(event) => this.signatureCanvas.clear()}>Repetir firma</Button>
                                        </div>
                                    </div>

                                </div>
                            )}
                            {this.state.dialogTitle === "Retiros o Ingresos de efectivo" && (
                                <div>
                                    <FormControl fullWidth>
                                        <InputLabel id={"type-select-label"}>Concepto</InputLabel>
                                        <Select value={this.state.cashMoveType} labelId={"type-select-label"} id={"type-select"} label={"Concepto"} onChange={(event) => this.setState({cashMoveType: event.target.value})}>
                                            {this.state.cashMoveTypes.map((type) => (
                                                <MenuItem value={type["_id"]["$oid"]}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                        <TextField value={this.state.cashMoveQ} label={"Monto"} onChange={(event) => this.setState({cashMoveQ: event.target.value})}/>
                                </div>
                            )}
                        </DialogContent>
                    <DialogActions>
                        {this.state.dialogTitle === "Cobrar" &&
                            (
                                <div>
                                    <Button onClick={() => this.setState({partialPay: {}, paymentField: "", payment: 0, change: 0})}>
                                        Limpiar
                                    </Button>
                                    <Button onClick={() => this.createTransaction()}>
                                        Boleta
                                    </Button>
                                </div>
                            )}
                        {(this.state.dialogTitle === "Cierre de turno" && this.state.otherPaymentDeclaration === false && this.state.goSignature === false ) &&
                            (
                                <Button onClick={() => this.setState({otherPaymentDeclaration: true})}>
                                    Avanzar
                                </Button>
                            )
                        }
                        {(this.state.dialogTitle === "Cierre de turno" && this.state.otherPaymentDeclaration === true ) &&
                            (
                                <Button onClick={() => this.setState({goSignature: true, otherPaymentDeclaration: false})}>
                                    Firmar
                                </Button>
                            )
                        }
                        {(this.state.dialogTitle === "Cierre de turno" && this.state.goSignature === true) &&
                            (
                                <Button onClick={() => this.closeShift()}>
                                    Cerrar turno
                                </Button>
                            )
                        }
                        {this.state.dialogTitle === "Retiros o Ingresos de efectivo" &&
                            (
                                <Button onClick={() => this.createCashMove()}>
                                    Realizar movimiento
                                </Button>
                            )
                        }
                    </DialogActions>
                </Dialog>
            </div>}
        </div>)
    }
}

export default Sales;