import logo from './logo.svg';
import './App.css';
import {Grid, Table, TableHead, TableBody, TableRow, TableCell, Button} from "@mui/material";
import React from "react";



class Kitchen extends React.Component {
  constructor(props) {
    super(props);
    this.state  = {orders: [], currentStore: null, stores: []};
    this.reloadOrders = this.reloadOrders.bind(this);
    this.completeOrder = this.completeOrder.bind(this);
    this.position = this.position.bind(this);
    this.findCurrentStore = this.findCurrentStore.bind(this);
    this.haversineDistance = this.haversineDistance.bind(this);
    this.timer = setInterval(this.reloadOrders, 5 * 1000); //originalmente en ms


  }
  componentDidMount() {

    fetch("https://apps.getitchile.cl/api/stores").then(result => result.json()).then(data => {this.setState({stores: data}); this.position()});
  }

  async position() {
    await navigator.geolocation.getCurrentPosition((position) => this.findCurrentStore(position.coords.latitude, position.coords.longitude),(error) => console.log(error));
  }

  findCurrentStore(latitude, longitude) {
    let currentStore = this.state.stores.filter((store) => this.haversineDistance(latitude, parseFloat(store.latitude), longitude, parseFloat(store.longitude)) <= 90);
    console.log("currentStore de la función findCurrentStore");
    console.log(currentStore);
    if (currentStore.length > 0) {
      this.setState({currentStore: currentStore[0]})
      this.reloadOrders()
    }
  }

  haversineDistance(la1, la2, lo1, lo2) {
    let x1 = Math.PI * (la2 -la1) / 180.0;
    let x2 = Math.PI * (lo2 - lo1) /180.0;
    let la1r = Math.PI * la1 / 180.0;
    let la2r = Math.PI * la2 / 180.0;
    let a = Math.pow(Math.sin(x1/2.0), 2) + Math.cos(la1r) * Math.cos(la2r) * Math.pow(Math.sin(x2 / 2.0), 2);
    let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    console.log("actual haversine")
    console.log(c * 6371 * 1000)
    return c * 6371 * 1000
  }

  reloadOrders() {
    fetch("https://apps.getitchile.cl/api/selfOrders").then((response) => response.json()).then((data) => this.setState({orders: data}));
  }

  completeOrder(order) {
    order.complete = true;
    fetch(`https://apps.getitchile.cl/api/selfOrders?self_order_id=${order["_id"]["$oid"]}`, {method: "PUT", body: JSON.stringify(order)}).then((response) => response.json()).then(() => this.reloadOrders());
  }


  render() {
    return (
        <div className="App">
          <Grid container={true} className={"order-container"} spacing={{xs: 1}}>
            {this.state.orders.filter((order) => !order.complete && order.hasOwnProperty("store") && order.store !== null && order.store["_id"]["$oid"] === this.state.currentStore["_id"]["$oid"]).map((order) => (
                <Grid item={true}  xs={6}>
                  <div className={"order-card"}>
                    <div style={{display: "flex"}}>
                      <h1>{order.orderNumber}</h1>
                      <Button style={{marginLeft: "auto"}} onClick={(event) => this.completeOrder(order)}>
                        Orden completada
                      </Button>
                    </div>
                    <Table>
                      <TableBody>
                        {order.skus.filter((s) => !s.hasOwnProperty("comboDiscount")).map((sku, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <div>
                                  <h2>{sku.sku_name}</h2>
                                  <p className={"indented-extras"}>{sku.pan}</p>
                                  <p className={"indented-extras"}>{sku.miga}</p>
                                  <p className={"indented-extras"}>{sku.temperature}</p>
                                  {sku.hasOwnProperty("extras") && sku.extras.map((extra) => (
                                      <p className={"indented-extras"}>{extra.sku_name}</p>
                                  ))}
                                </div>
                              </TableCell>
                              <TableCell>
                                <h2>{sku.quantity}</h2>
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
            ))}
          </Grid>
        </div>
    );
  }
}

export default Kitchen;
