import React from "react";
import {Box, Button, TextField} from "@mui/material";
import Quagga from "quagga";
import {sha256} from "js-sha256";


class Inventory extends React.Component {
    constructor(props) {
        super(props);
        this.state =  {login: false, scannedCode: null, loginEmail: null, loginPassword: null, loginUser: null, error: null, stores: [], currentStore: null, skus: {}};
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.scan = this.scan.bind(this);
        this.position = this.position.bind(this);
        this.findCurrentStore = this.findCurrentStore.bind(this);
        this.haversineDistance = this.haversineDistance.bind(this);
        this.offlineCache = this.offlineCache.bind(this);

    }

    componentDidMount() {
        fetch("https://apps.getitchile.cl/api/stores").then(result => result.json()).then(data => {this.offlineCache("stores", data); this.position()});
        fetch("https://apps.getitchile.cl/api/skus?barcode=true").then(result => result.json()).then(data => this.setState({skus: data}));
    }

    async position() {
        await navigator.geolocation.getCurrentPosition((position) => this.findCurrentStore(position.coords.latitude, position.coords.longitude),(error) => console.log(error));
    }

    findCurrentStore(latitude, longitude) {
        let currentStore = this.state.stores.filter((store) => this.haversineDistance(latitude, parseFloat(store.latitude), longitude, parseFloat(store.longitude)) <= 70);
        if (currentStore.length > 0) {
            this.setState({currentStore: currentStore[0]})

        }
    }

    offlineCache(collection, data=null) {
        if (data !== null) {
            this.setState({[collection]: data});
            localStorage.setItem(collection, JSON.stringify(data));
        } else {
            let cache = localStorage.getItem(collection)
            return cache ? JSON.parse(cache) : []
        }


    }

    haversineDistance(la1, la2, lo1, lo2) {
        let x1 = Math.PI * (la2 -la1) / 180.0;
        let x2 = Math.PI * (lo2 - lo1) /180.0;
        let la1r = Math.PI * la1 / 180.0;
        let la2r = Math.PI * la2 / 180.0;
        let a = Math.pow(Math.sin(x1/2.0), 2) + Math.cos(la1r) * Math.cos(la2r) * Math.pow(Math.sin(x2 / 2.0), 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return c * 6371 * 1000
    }

    scan() {
        Quagga.init({inputStream: {type: "LiveStream", target: document.querySelector("#camara-inventario"), constraints: {facingMode: "environment"}},
            numOfWorkers: navigator.hardwareConcurrency,
            locate: true,
            frequency: 10,
            multiple: false,
            locator: {halfSample: true},
            decoder: {readers: ['code_128_reader',
                    'ean_reader',
                    'ean_8_reader',
                    'code_39_reader',
                    'code_39_vin_reader',
                    'codabar_reader',
                    'upc_reader',
                    'upc_e_reader',
                    'i2of5_reader',
                    'i2of5_reader',
                    '2of5_reader',
                    'code_93_reader']}}, (error) => {
            if (error) {
                this.setState({error: error})
                return
            }
            Quagga.start();
        })
        Quagga.onDetected((result) => {
            if (this.state.skus.hasOwnProperty(result.codeResult.code)) {
                let sku = this.state.skus[result.codeResult.code];
                this.setState({scannedCode: sku.sku_name})
            }
        });
    }

    login(event) {
        let body = {email: this.state.loginEmail, password: sha256(this.state.loginPassword)};
        fetch("https://apps.getitchile.cl/api/login", {method: "post", headers: {"Content-Type": "application/json"}, body: JSON.stringify(body)}).then(response => response.json()).then(data => {this.setState({login: data.status === "Ok", loginUser: data.user}); this.scan()})
        event.preventDefault();
    }

    logout() {
        this.setState({loginEmail: null, loginPassword: null, loginUser: null, login: false})
    }



    render() {
        return (

        <div>
            {!this.state.login &&
                <Box component={"form"} noValidate>
                    <h1 className={"admin-title"}>Ingresa tus datos</h1>
                    <div className={"field-div"}>
                        <TextField onChange={event => this.setState({loginEmail: event.target.value})} name={"email"} id={"email"} fullWidth={true} className={"admin-fields"} label={"email"}/>
                    </div>
                    <div className={"field-div"}>
                        <TextField onChange={event => this.setState({loginPassword: event.target.value})} name={"password"} id={"password"} type={"password"} fullWidth={true} className={"admin-fields"} label={"password"}/>
                    </div>
                    <Button className={"login-button"} onClick={this.login}>Iniciar sesión</Button>
                </Box>}
            {this.state.login &&
            <div>
                <div id="camara-inventario" style={{maxWidth: "100%", maxHeight: "90vh"}}>

                </div>
                <p>Código de barras: {this.state.scannedCode}</p>
                <p>{this.state.error}</p>
            </div>
            }
        </div>
        )
    }
}

export default Inventory