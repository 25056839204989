import logo from './logo.svg';
import './App.css';
import Categories from "./Categories";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Admin from "./Admin";
import Sales from "./Sales";
import Kitchen from "./Kitchen";
import Inventory from "./Inventory";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <BrowserRouter className={"app"}>
            <Routes>
                <Route path= "/" element={<Categories/>}/>
                <Route path={ "admin"} element={<Admin/>}/>
                <Route path={ "sales"} element={<Sales/>}/>
                <Route path={ "kitchen"} element={<Kitchen/>}/>
                <Route path={ "inventory"} element={<Inventory/>}/>
            </Routes>
        </BrowserRouter>
    </LocalizationProvider>
  );
}


export default App;
