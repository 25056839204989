import React from "react";
import {Button, Table, TableBody, TableCell, TableContainer, TableRow, TableHead} from "@mui/material";

class ObjectTable extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className={"new-sku-button"}>
                    <h1>{this.props.title}</h1>
                    <Button onClick={() => this.props.container.setState({create: true, [`selected${this.props.name}`]: null})}>+</Button>
                </div>
                <TableContainer>
                    <Table>
                        <TableHead>
                            {this.props.inputs.length > 0 && <TableCell>Nombre</TableCell>}
                            {this.props.hasOwnProperty("extraColumns") &&
                            this.props.extraColumns.map((column) => <TableCell>{column.name}</TableCell>)
                            }
                            <TableCell>Acciones</TableCell>
                        </TableHead>
                        <TableBody>
                            {this.props.objects?.map(object => (
                                <TableRow onClick={() => this.props.container.setState({[`selected${this.props.name}`]: object})}>
                                    {this.props.inputs.length > 0 && <TableCell>
                                        {(!this.props.hasOwnProperty("subproperty")) && object.name}
                                        {(this.props.hasOwnProperty("subproperty")) && object[this.props.property][this.props.subproperty]}
                                    </TableCell>}
                                    {this.props.hasOwnProperty("extraColumns") &&
                                        this.props.extraColumns.map((column) => <TableCell>{(column.hasOwnProperty("condition") && object[column.condition]) ? column.conditionLabel : (Array.isArray(object[column.property])) ? object[column.property].map((v) => <p>{(column.hasOwnProperty("collection")) ? column.collection.find((i) => (column.hasOwnProperty("subproperty")) ? i[column.subproperty] === v : i["_id"]["$oid"] === v).name : v}</p>) : (column.hasOwnProperty("collection")) ? column.collection.find((i) => (column.hasOwnProperty("subproperty")) ? i[column.subproperty] === object[column.property] : i["_id"]["$oid"] === object[column.property]).name : object[column.property]}</TableCell>)
                                    }
                                    <TableCell>
                                        <Button onClick={() => this.props.container.setState({[`selected${this.props.name}`]: object, create: true})}>Editar</Button>
                                        <Button onClick={() => this.props.delete(this.props.name.toLowerCase(), object, this.props.collection, this.props.container, `selected${this.props.name}`, this.props.inputs)}>Borrar</Button>
                                    </TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }
}

export default ObjectTable;